import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
// import { isUserLoggedIn } from "../actions";
import { AuthPage } from "../containers/auth/AuthPage";
import { ErrorsPage } from "../containers/errors/ErrorsPage";
import { USER_ROLE } from "../helpers/Constants";
import { getUserToken } from "../_metronic/helpers";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { PrivateRoute } from "./PrivateRoute";
import { OrganiserPrivateRoute } from "./OrganiserPrivateRoute";

const Routes: FC = () => {
  // const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);
  const userToken = getUserToken();

  const userrole = useMemo(() => {
    return auth.userRole;
  }, [auth.userRole]);

  return (
    <>
      <Switch>
        {!userToken ? (
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />

        {!userToken ? (
          <Redirect to="/auth/login" />
        ) : (
          <>
            <MasterLayout>
              {userrole === USER_ROLE.ADMIN ? (
                <PrivateRoute />
              ) : (
                <OrganiserPrivateRoute />
              )}
            </MasterLayout>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  );
};

export { Routes };

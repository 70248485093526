import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import DashboardWrapper from "../containers/Dashboard/DashboardWrapper";
import { FallbackView } from "../_metronic/partials";
import Organisers from "../containers/organisers";

export function PrivateRoute() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path="/organisers" component={Organisers} />
        <Route exact path={["/","/dashboard"]} component={DashboardWrapper} />
      </Switch>
    </Suspense>
  );
}

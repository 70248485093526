export const BASE_URL = "http://178.62.98.182:5001/";
export const IAMGE_BASE_URL = "http://178.62.98.182:5001/uploads";
export const API_CONST = "muthrab";
export const API_VERSION = "v1";
export const LOGIN_STORAGE_KEY = "muthrab:authUser";
export const ENDPOINT = {
  // admin end point
  ADMINLOGIN: "/admin/signin",
  ADMINDASHBOARD: "/admin/dashboard",
  ORGANISERLIST: "/admin/organizer_list",
  FORGOTPASSWORD_SENDOTP: "/admin/send_otp",
  VERIFYOTP: "/admin/verify_otp",
  RESETPASSWORD: "/admin/reset_password",
  
  // organiser end point
  ORGANISERLOGIN: "/organiser/signin",
  CREATEORGANISER: "/organiser/signup",
  EDITORGANISER: "/organiser/edit",
  DELETEORGANISER: "/organiser/delete",

  // tournament end point
  ONGOING: "/tournament/organiserongoingtournament",
  UPCOMING: "/tournament/organiserupcomingtournament",
  PREVIOUS: "/tournament/organiserprevioustournament",
  CREATE_TOURNAMENT: "/tournament/create",
  ORGANISERUSERLIST: "/organiser/user_list",
  ALLOWTEAMLIST: "/tournament/get_allow_teams",

  // common end point
  TYPESLIST: "/common/alltypes",
  FORMATLIST: "/common/allformat",
  SPORTLIST: "/common/allsports",
  CATEGORYLIST: "/common/allcategories",
  LEVELSLIST: "/common/alllevels",
};

import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPassword, verifyPassword } from "../../../actions";

export function VerifyOTP(props: any) {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState<any>("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (otp: string) => {
    setOtp(otp);
  };

  const resendOTP = () => {
    console.log();

    let formData = new FormData();
    formData.append("email_address", props?.email);
    formData.append("userrole", props?.isUserAdmin ? "admin" : "organiser");
    dispatch(
      forgotPassword(formData, (res: any) => {
        if (res?.statuscode === 1) {
          toast.success(`${res.message}`);
        } else {
          toast.error(`${res.message}`);
        }
      })
    );
  };

  const verifyOTP = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("email_address", props?.email);
    formData.append("otp", otp);
    formData.append("userrole", props?.isUserAdmin ? "admin" : "organiser");
    dispatch(
      verifyPassword(formData, (res: any) => {
        setLoading(false);
        if (res?.statuscode === 1) {
          toast.success(`${res.message}`);
          history.push({
            pathname: props?.isUserAdmin
              ? "/auth/admin/reset-password"
              : "/auth/reset-password",
            state: { email: props?.email, isUserAdmin: props?.isUserAdmin },
          });
        } else {
          toast.error(`${res.message}`);
        }
      })
    );
  };

  return (
    <>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Enter OTP</h1>
        <div className="text-gray-400 fw-bold fs-4">
          Enter valid OTP which send to your registered email to password reset.
        </div>
      </div>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={4}
        containerStyle="otp-container mb-7"
        inputStyle="otp-input"
        separator={<span>-</span>}
      />
      <div className="d-flex flex-wrap justify-content-center mb-5 pb-lg-0">
        <button
          type="button"
          onClick={verifyOTP}
          id="kt_password_reset_submit"
          disabled={otp.length < 4}
          className="btn btn-lg btn-primary fw-bolder me-4"
        >
          <span className="indicator-label">Verify</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <div className="text-gray-400 text-center mt-2 fw-bold fs-6">
        Did not receive code?{" "}
        <a href="javascript:void(0)" onClick={resendOTP}>
          Resend.
        </a>
      </div>
    </>
  );
}

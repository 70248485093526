import moment from "moment";
import { logoutUser } from "../../actions";

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}

export function logOut(dispatch: Function) {
  dispatch(logoutUser());
}

export function formatDate(date: any, format: string) {
  return moment(date).format(format);
}

export function getUserToken() {
  return localStorage.getItem("token");
}

import { Console } from "console";
import { dashboardConstants } from "../actions/constants";

const initialStateOfState = {
  users: 0,
  organisers: 0,
  previous_tournaments: 0,
  ongoing_tournaments: 0,
  upcoming_tournaments: 0,
  message: "",
  statuscode: -1,
};

const initialStateOfAction = {
  type: "",
  payload: {
    data: {
      users: 0,
      organisers: 0,
      previous_tournaments: 0,
      ongoing_tournaments: 0,
      upcoming_tournaments: 0,
    },
    message: "",
    statuscode: -1,
    success: false,
  },
};

export default (state = initialStateOfState, action = initialStateOfAction) => {
  switch (action.type) {
    case dashboardConstants.DASHBORD_USER_REQUEST:
      state = {
        ...state,
        statuscode: -1,
      };
      break;
    case dashboardConstants.DASHBORD_USER_SUCCESS:
      state = {
        ...state,
        ...action.payload.data,
        message: action.payload.message,
        statuscode: action.payload.statuscode,
      };
      break;
    case dashboardConstants.DASHBORD_USER_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        statuscode: 0,
      };
      break;
  }
  return state;
};

export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  VERIY_OTP_REQUEST: "VERIY_OTP_REQUEST",
  VERIY_OTP_SUCCESS: "VERIY_OTP_SUCCESS",
  VERIY_OTP_FAILURE: "VERIY_OTP_FAILURE",
};

export const commonConstants = {
  TYPE_LIST_SUCCESS: "TYPE_LIST_SUCCESS",
  TYPE_LIST_FAILURE: "TYPE_LIST_FAILURE",
  TYPE_LIST_REQUEST: "TYPE_LIST_REQUEST",

  FORMAT_LIST_SUCCESS: "FORMAT_LIST_SUCCESS",
  FORMAT_LIST_FAILURE: "FORMAT_LIST_FAILURE",
  FORMAT_LIST_REQUEST: "FORMAT_LIST_REQUEST",

  SPORTS_LIST_SUCCESS: "SPORTS_LIST_SUCCESS",
  SPORTS_LIST_FAILURE: "SPORTS_LIST_FAILURE",
  SPORTS_LIST_REQUEST: "SPORTS_LIST_REQUEST",

  CATEGORY_LIST_SUCCESS: "CATEGORY_LIST_SUCCESS",
  CATEGORY_LIST_FAILURE: "CATEGORY_LIST_FAILURE",
  CATEGORY_LIST_REQUEST: "CATEGORY_LIST_REQUEST",

  LAVEL_LIST_SUCCESS: "LAVEL_LIST_SUCCESS",
  LAVEL_LIST_FAILURE: "LAVEL_LIST_FAILURE",
  LAVEL_LIST_REQUEST: "LAVEL_LIST_REQUEST",
};

export const dashboardConstants = {
  DASHBORD_USER_SUCCESS: "DASHBORD_USER_SUCCESS",
  DASHBORD_USER_FAILURE: "DASHBORD_USER_FAILURE",
  DASHBORD_USER_REQUEST: "DASHBORD_USER_REQUEST",

  DASHBORD_USER_SUCCESS_EARNINGS: "DASHBORD_USER_SUCCESS_EARNINGS",
  DASHBORD_USER_FAILURE_EARNINGS: "DASHBORD_USER_FAILURE_EARNINGS",
  DASHBORD_USER_REQUEST_EARNINGS: "DASHBORD_USER_REQUEST_EARNINGS",
};

export const organizerConstants = {
  CREATE_ORGANISER_SUCCESS: "CREATE_ORGANISER_SUCCESS",
  CREATE_ORGANISER_FAILURE: "CREATE_ORGANISER_FAILURE",
  CREATE_ORGANISER_REQUEST: "CREATE_ORGANISER_REQUEST",

  EDIT_ORGANISER_SUCCESS: "EDIT_ORGANISER_SUCCESS",
  EDIT_ORGANISER_FAILURE: "EDIT_ORGANISER_FAILURE",
  EDIT_ORGANISER_REQUEST: "EDIT_ORGANISER_REQUEST",

  GET_ORGANISER_SUCCESS: "GET_ORGANISER_SUCCESS",
  GET_ORGANISER_FAILURE: "GET_ORGANISER_FAILURE",
  GET_ORGANISER_REQUEST: "GET_ORGANISER_REQUEST",

  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_USERS_REQUEST: "GET_USERS_REQUEST",

  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_FAILURE: "DELETE_USERS_FAILURE",
  DELETE_USERS_REQUEST: "DELETE_USERS_REQUEST",

  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE: "GET_USER_DETAIL_FAILURE",
  GET_USER_DETAIL_REQUEST: "GET_USER_DETAIL_REQUEST",
};

export const tournamentConstants = {
  ONGOING_TOURNAMENT_SUCCESS: "ONGOING_TOURNAMENT_SUCCESS",
  ONGOING_TOURNAMENT_FAILURE: "ONGOING_TOURNAMENT_FAILURE",
  ONGOING_TOURNAMENT_REQUEST: "ONGOING_TOURNAMENT_REQUEST",

  PREVIOUS_TOURNAMENT_SUCCESS: "PREVIOUS_TOURNAMENT_SUCCESS",
  PREVIOUS_TOURNAMENT_REQUEST: "PREVIOUS_TOURNAMENT_REQUEST",
  PREVIOUS_TOURNAMENT_FAILURE: "PREVIOUS_TOURNAMENT_FAILURE",

  UPCOMING_TOURNAMENT_FAILURE: "UPCOMING_TOURNAMENT_FAILURE",
  UPCOMING_TOURNAMENT_REQUEST: "UPCOMING_TOURNAMENT_REQUEST",
  UPCOMING_TOURNAMENT_SUCCESS: "UPCOMING_TOURNAMENT_SUCCESS",

  CREATE_TOURNAMENT_FAILURE: "CREATE_TOURNAMENT_FAILURE",
  CREATE_TOURNAMENT_REQUEST: "CREATE_TOURNAMENT_REQUEST",
  CREATE_TOURNAMENT_SUCCESS: "CREATE_TOURNAMENT_SUCCESS",

  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAILURE: "USER_LIST_FAILURE",
  USER_LIST_REQUEST: "USER_LIST_REQUEST",

  GET_ALLOW_TEAMS_SUCCESS: "GET_ALLOW_TEAMS_SUCCESS",
  GET_ALLOW_TEAMS_FAILURE: "GET_ALLOW_TEAMS_FAILURE",
  GET_ALLOW_TEAMS_REQUEST: "GET_ALLOW_TEAMS_REQUEST",
};

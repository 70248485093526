import { API_CONST, ENDPOINT } from "../Configs";
import { STATUS_CODE } from "../helpers/Constants";
import { dashboardConstants } from "./constants";
import api_helper from "../Configs/API_helper";
const api = new api_helper();

export const getdashbord = () => {
  return async (dispatch: Function) => {
    dispatch({ type: dashboardConstants.DASHBORD_USER_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.ADMINDASHBOARD}`);
    const { data, statuscode, message } = response.data;

    if (response.status === 200) {
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: dashboardConstants.DASHBORD_USER_SUCCESS,
          payload: {
            data,
            message,
            statuscode,
          },
        });
      } else {
        dispatch({
          type: dashboardConstants.DASHBORD_USER_FAILURE,
          payload: {
            message,
            statuscode,
            error: data.error,
          },
        });
      }
    }
  };
};

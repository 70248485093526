import React, { useEffect } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { ForgotPassword } from "./components/ForgotPassword";
import { ResetPassword } from "./components/ResetPassword";
import { Login } from "./components/Login";

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);
  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          "/media/illustrations/sketchy-1/14.png"
        )})`,
      }}
    >
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <Link to="/" className="mb-12 text-center">
          <img
            className="title-logo"
            src="/media/Logo/PNG/Logo/main-logo.svg"
            alt=""
          />
          {/* <h1 style={{ fontSize: "30px" }}>Muthrab</h1> */}
        </Link>
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Switch>
            <Route
              exact
              path={["/auth/admin/login", "/auth/login"]}
              component={Login}
            />
            <Route
              exact
              path={["/auth/admin/forgot-password", "/auth/forgot-password"]}
              component={ForgotPassword}
            />
            <Route
              exact
              path={["/auth/admin/reset-password", "/auth/reset-password"]}
              component={ResetPassword}
            />
            <Redirect from="/" exact={true} to="/auth/login" />
            {/* <Redirect  exact={true} to="/auth" /> */}
          </Switch>
        </div>
      </div>
      <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a
            href="javascript:void(0)"
            className="text-muted text-hover-primary px-2"
          >
            About
          </a>

          <a
            href="javascript:void(0)"
            className="text-muted text-hover-primary px-2"
          >
            Contact
          </a>

          <a
            href="javascript:void(0)"
            className="text-muted text-hover-primary px-2"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap-v5";
import clsx from "clsx";
import * as Yup from "yup";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { useDispatch, useSelector } from "react-redux";
import { createOrganiser, editOrganiser } from "../../actions";
import { toast } from "react-toastify";
import { IAMGE_BASE_URL } from "../../Configs";
// import { IoIosCloseCircleOutline } from "react-icons/io";

const initialValues = {
  name: "",
  email_address: "",
  password: "",
};

const AddOrganizerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Organizer name is required"),
  email_address: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const EditOrganizerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Organizer name is required"),
  email_address: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

const AddEditOrganizerPopup: React.FC<any> = ({
  show,
  handleClose,
  submitPopup,
  data,
}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [preview, setPreview] = useState<string | null>();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const closepopup = () => {
    handleClose();
  };

  const changeProfileImage = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmitOrganizer = (formValues: any) => {
    let formData = new FormData();
    formData.append("name", formValues.name);
    formData.append("email_address", formValues.email_address);
    formData.append("password", formValues.password);

    if (selectedFile) {
      formData.append("organiser_profile_picture", selectedFile);
    }

    if (data) {
      formData.append("organiser_id", data._id);
      if (!selectedFile) {
        formData.append(
          "organiser_profile_picture",
          data?.organiser_profile_picture
        );
      }
      dispatch(editOrganiser(formData));
    } else {
      dispatch(createOrganiser(formData));
    }
  };

  const EditDataImage = useMemo(() => {
    if (data?.organiser_profile_picture) {
      return `${IAMGE_BASE_URL}/${data?.organiser_profile_picture}`;
    }
  }, [data?.organiser_profile_picture]);

  return (
    <Modal
      className="modal-sticky absolute_center modal-sticky-lg modal-sticky-bottom-right "
      id="kt_inbox_compose"
      role="dialog"
      data-backdrop="false"
      aria-hidden="true"
      tabIndex="-1"
      show={show}
      animation={true}
    >
      <div className="modal-content">
        <Formik
          enableReinitialize={true}
          initialValues={
            {
              name: data?.name,
              email_address: data?.email_address,
              password: data?.password,
            } || initialValues
          }
          validationSchema={data ? EditOrganizerSchema : AddOrganizerSchema}
          onSubmit={(values) => {
            handleSubmitOrganizer(values);
          }}
        >
          {(formik) => (
            <Form id="kt_inbox_compose_form">
              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : null}

              {console.log("formik.errors")}
              {/* <form id="kt_inbox_compose_form" onSubmit={(e) => submitPopup(e)}> */}
              <div className="px-5 pt-5 row ">
                <div className="d-flex align-items-center justify-content-between ms-2">
                  <p
                    className="fw-normal text-center mt-3"
                    style={{ fontSize: "20px" }}
                  >
                    {data ? "Edit" : "Create"} Organizer
                  </p>
                  <div
                    className="btn btn-icon btn-sm btn-outline-danger ms-2"
                    data-bs-dismiss="modal"
                    data-bs-toggle="tooltip"
                    title="Close"
                    onClick={() => closepopup()}
                  >
                    <KTSVG
                      className="svg-icon-1"
                      path="/media/icons/duotune/arrows/arr061.svg"
                    />
                  </div>
                </div>
              </div>
              <div className="d-block">
                <div className="card-body border-top pt-9 px-9">
                  <div className="row mb-6">
                    <div className="col-lg d-flex justify-content-center">
                      <div
                        className="image-input image-input-outline image-input-empty"
                        data-kt-image-input="true"
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl(
                            "/media/avatars/blank.png"
                          )})`,
                        }}
                      >
                        <div
                          className="image-input-wrapper organiser-profile w-150px h-150px"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              preview || EditDataImage || selectedFile
                            )})`,
                          }}
                        ></div>
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary m-0 w-25px h-25px bg-body shadow top-0 start-100 m-n4  position-absolute"
                          htmlFor=""
                        >
                          <i
                            className="bi bi-pencil-fill fs-7 top-0 start-100 ms-n6 pt-2 position-absolute"
                            data-bs-trigger="hover"
                            title="Edit Profile pick"
                          >
                            <span style={{ opacity: 0 }}>
                              <input
                                type="file"
                                className="bi bi-pencil-fill fs-7 ms-n9 w-35px"
                                accept=".png, .jpg ,.jpeg"
                                onChange={(e) => changeProfileImage(e)}
                              />
                            </span>
                          </i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-9 pt-5">
                  <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Organizer Name
                    </label>
                    <input
                      placeholder="organizer"
                      {...formik.getFieldProps("name")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched.name && formik.errors.name,
                        },
                        {
                          "is-valid":
                            formik.touched.name && !formik.errors.name,
                        }
                      )}
                      type="text"
                      defaultValue={data?.name || ""}
                      name="name"
                      autoComplete="off"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <span role="alert">{formik.errors.name}</span>
                      </div>
                    )}
                  </div>

                  <div className="fv-row mb-10">
                    <div className="d-flex justify-content-between mt-n5">
                      <div className="d-flex flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">
                          Email
                        </label>
                      </div>
                    </div>
                    <input
                      placeholder="muthrab@example.com"
                      {...formik.getFieldProps("email_address")}
                      className={clsx(
                        "form-control form-control-lg form-control-solid",
                        {
                          "is-invalid":
                            formik.touched.email_address &&
                            formik.errors.email_address,
                        },
                        {
                          "is-valid":
                            formik.touched.email_address &&
                            !formik.errors.email_address,
                        }
                      )}
                      type="email"
                      name="email_address"
                      autoComplete="off"
                    />
                    {formik.touched.email_address &&
                      formik.errors.email_address && (
                        <div className="fv-plugins-message-container">
                          <span role="alert">
                            {formik.errors.email_address}
                          </span>
                        </div>
                      )}
                  </div>

                  {!data && (
                    <div className="fv-row mb-10">
                      <div className="d-flex justify-content-between mt-n5">
                        <div className="d-flex flex-stack mb-2">
                          <label className="form-label fw-bolder text-dark fs-6 mb-0">
                            Password
                          </label>
                        </div>
                      </div>
                      <input
                        type="password"
                        autoComplete="off"
                        placeholder="password"
                        {...formik.getFieldProps("password")}
                        className={clsx(
                          "form-control form-control-lg form-control-solid",
                          {
                            "is-invalid":
                              formik.touched.password && formik.errors.password,
                          },
                          {
                            "is-valid":
                              formik.touched.password &&
                              !formik.errors.password,
                          }
                        )}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{formik.errors.password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className=" py-5 ps-8 pe-5 ">
                <div className="row justify-content-center mb-15">
                  <div className="col d-flex  justify-content-end ">
                    <button
                      type="button"
                      className="btn btn-secondary me-4 px-10 "
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 px-10"
                    >
                      {data ? "Edit" : "Add"} Organizer
                    </button>
                  </div>
                </div>
              </div>
              {/* </form> */}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export { AddEditOrganizerPopup };

import { useHistory } from "react-router-dom";
import { API_CONST, ENDPOINT } from "../Configs";
import { STATUS_CODE } from "../helpers/Constants";
import { tournamentConstants } from "./constants";
import api_helper from "../Configs/API_helper";
const api = new api_helper();

export const getOngoingTournamentList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: tournamentConstants.ONGOING_TOURNAMENT_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.ONGOING}`);
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: tournamentConstants.ONGOING_TOURNAMENT_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: tournamentConstants.ONGOING_TOURNAMENT_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const getPreviousTournamentList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: tournamentConstants.PREVIOUS_TOURNAMENT_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.PREVIOUS}`);
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: tournamentConstants.PREVIOUS_TOURNAMENT_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: tournamentConstants.PREVIOUS_TOURNAMENT_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const getUpcomingTournamentList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: tournamentConstants.UPCOMING_TOURNAMENT_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.UPCOMING}`);
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: tournamentConstants.UPCOMING_TOURNAMENT_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: tournamentConstants.UPCOMING_TOURNAMENT_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const createTournamentAction = (tournamentData: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: tournamentConstants.CREATE_TOURNAMENT_REQUEST });
    const response = await api.post(
      `${API_CONST}${ENDPOINT.CREATE_TOURNAMENT}`,
      {
        data: tournamentData,
      }
    );
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: tournamentConstants.CREATE_TOURNAMENT_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: tournamentConstants.CREATE_TOURNAMENT_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const getAllUserAction = () => {
  return async (dispatch: Function) => {
    dispatch({ type: tournamentConstants.USER_LIST_REQUEST });
    const response = await api.post(
      `${API_CONST}${ENDPOINT.ORGANISERUSERLIST}`
    );
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: tournamentConstants.USER_LIST_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: tournamentConstants.USER_LIST_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const getAllowTeams = (typeId: string) => {
  return async (dispatch: Function) => {
    dispatch({ type: tournamentConstants.GET_ALLOW_TEAMS_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.ALLOWTEAMLIST}`, {
      data: { type: typeId },
    });
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: tournamentConstants.GET_ALLOW_TEAMS_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: tournamentConstants.GET_ALLOW_TEAMS_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

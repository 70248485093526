/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import { Col, Row } from "reactstrap";
import { RiArrowUpDownLine } from "react-icons/ri";
import { HiArrowNarrowDown } from "react-icons/hi";
import { HiArrowNarrowUp } from "react-icons/hi";
import PaginationLayout from "./React-Table/pagination";
import { KTSVG } from "../../../helpers";

interface PageSizeProps {
  pageSize: any;
  setPageSize: (pageSize: number) => void;
}

const GlobalFilter: React.FC<any> = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="d-flex align-items-center justify-content-end my-3 position-relative">
      <div className="search-box mb-2 d-inline-block">
        <div className="position-relative">
          <span>  </span>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
          />
          {/* <input */}
          {/* // type="text" */}

          {/* className="form-control" */}
          {/* placeholder={`Search...`} */}
          {/* /> */}
          <input
            type='text'
            className='form-control form-control-solid px-15'
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            name='search'
            placeholder='Search...'
          />
        </div>
      </div>
    </div>
  );
};

const ShowPageSize1: React.FC<PageSizeProps> = ({ setPageSize, pageSize }) => {
  // Render the UI for your table
  return (
    <>
      <div className="d-flex align-items-center justify-content-flex-start mb-1">
        <label className="fs-4" htmlFor="autoSizingSelect">
          &nbsp;Show &nbsp;
        </label>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className="form-select w-auto pageSize"
          id="page-size"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
        <label className="fs-4" htmlFor="autoSizingSelect">
          &nbsp;Entries&nbsp;
        </label>
      </div>
    </>
  );
};
type Coluns = {
  Header: string,
  accessor: string,
  disableSortBy:boolean,
  Cell:any
}

type Props = {
  columns:Coluns|any;
  //  Array<Column<object>>;
  tablepageSize?: any;
  isShowPageSize?: boolean;
  data: Array<Object>;
  isShowCountry?: boolean;
  classname?: string;
};

const TablesWidget1: React.FC<Props> = ({
  classname,
  columns,
  tablepageSize,
  data,
  isShowCountry,
  isShowPageSize,
}) => {
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: tablepageSize || 10 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
    useFlexLayout,
    usePagination
  );

  useEffect(() => {
    if (tablepageSize) {
      setPageSize(Number(tablepageSize));
    }
  }, [tablepageSize]);

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <HiArrowNarrowDown className="ms-5" />
      ) : (
        <HiArrowNarrowUp className="ms-5" />
      )
    ) : column.disableSortBy ? (
      ""
    ) : (
      <RiArrowUpDownLine className="ms-5" />
    );
  };

  return (
    <div className={`card ${classname}`}>
      {/* begin::Header */}
      <div className="border-0 pt-5">
        <Row className="mt-5">
          {isShowPageSize && (
            <Col md={4}>
              <Row>
                <Col md={1}></Col>
                <Col md={11} className="mt-3 ">
                  <div className="d-flex align-items-center justify-content-flex-start mb-1 ">
                    <label className="fs-4" htmlFor="autoSizingSelect">
                      &nbsp;Show &nbsp;
                    </label>
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value));
                      }}
                      className="form-select w-auto pageSize "
                      id="page-size"
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                    <label className="fs-4" htmlFor="autoSizingSelect">
                      &nbsp;Entries&nbsp;
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          <Col>
            <Col xs="12" sm="6" md="8" className="float-end me-5">
              <GlobalFilter
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Col>
          </Col>
        </Row>
      </div>

      <div className="card-body py-3 pb-5">
        <div className="table-responsive pb-5 mb-5">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead >
              {headerGroups.map((headerGroup) => (
                <tr
                  className="fw-bolder text-muted text-center"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      className={`min-w-100px`}
                      //   style={{ fontSize: `${classname}` }}
                      {...column.getHeaderProps()}
                    >
                      <div {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      {/* {isFilter && <Filter column={column} />} */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {/* <tbody className="table table-bordered" {...getTableBodyProps()}> */}
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td className="text-center" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Row className="mb-2 mt-4">
            <Col sm="12" md="12" className="text-md-end">
              <PaginationLayout
                totalPage={pageCount}
                page={pageIndex}
                onPageChange={gotoPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export { TablesWidget1, ShowPageSize1 };

import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "./index";

const STATUS_CODE = {
  INTERNAL_SERVER_ERROR: 500,
  NOT_FOUND: 404,
  NO_CONTENT: 204,
  SUCCESS: 200,
};

const METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// API controller that for handling the request
export default class apiService {
  userData = {};
  userToken = null;
  isLoggedIn = false;

  get(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PATCH, url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  patch(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PATCH, url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // common method in httpService class
  api(method, url, data) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;
      axiosConfig.url = url;
      axiosConfig.headers = this.setHeaders(data);

      if (data) {
        if (data.data) axiosConfig.data = data.data;
        if (data.params) axiosConfig.params = data.params;
      }

      axiosInstance(axiosConfig)
        .then((response) => {
          if (response) {
            if (response.data) {
              resolve(response);
            } else if (response.status == STATUS_CODE.INTERNAL_SERVER_ERROR) {
              resolve({
                data: response.data,
                status: false,
                message: "INTERNAL_SERVER_ERROR",
              });
            } else if (response.status == STATUS_CODE.NO_CONTENT) {
              resolve({
                data: response.data,
                status: false,
                message: "NO_CONTENT",
              });
            } else if (response.status == STATUS_CODE.NOT_FOUND) {
              resolve({
                data: response.data,
                status: false,
                message: "NOT_FOUND",
              });
            } else {
              resolve({
                data: response.data,
                status: false,
                message: "Somthing went wrong",
              });
            }
          }
        })
        .catch((error) => {
          //console.log(error)

          const err_msg =
            error.data && error.response.data.message
              ? error.response.data.message
              : error.message;

          toast.error(err_msg);

          reject(err_msg);
        });
    });
  }

  // set header for request method in httpService class
  setHeaders(data) {
    let headers = {};
    headers["Access-Control-Allow-Origin"] = "*";
    headers["accept-language"] = "en";
    headers["Content-Type"] = "application/json";

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (data) {
      if (data.isMultipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (data.headers) {
        for (var key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }
    return headers;
  }
}

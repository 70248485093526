import { tournamentConstants } from "../actions/constants";

const initialState = {
  ongoingTournamentList: [],
  previousTournamentList: [],
  upcomingTournamentList: [],
  userList: [],
  allowTeamList: [],
  message: "",
  status: 2,
};

type TournamentType = {
  payload: {
    data: [] | any;
    status: number;
    message: string;
  };
  type: string;
};

export default (state = initialState, action: TournamentType) => {
  console.log("action.payload.data", action.payload);

  switch (action.type) {
    case tournamentConstants.ONGOING_TOURNAMENT_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case tournamentConstants.ONGOING_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        ongoingTournamentList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case tournamentConstants.ONGOING_TOURNAMENT_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case tournamentConstants.PREVIOUS_TOURNAMENT_REQUEST:
      state = {
        ...state,
        message: "",
        status: 2,
      };
      break;
    case tournamentConstants.PREVIOUS_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        previousTournamentList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case tournamentConstants.PREVIOUS_TOURNAMENT_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;

    case tournamentConstants.UPCOMING_TOURNAMENT_REQUEST:
      state = {
        ...state,
        status: 2,
        message: "",
      };
      break;
    case tournamentConstants.UPCOMING_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        upcomingTournamentList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case tournamentConstants.UPCOMING_TOURNAMENT_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;

    case tournamentConstants.GET_ALLOW_TEAMS_REQUEST:
      state = {
        ...state,
        allowTeamList: [],
        status: 2,
      };
      break;
    case tournamentConstants.GET_ALLOW_TEAMS_SUCCESS:
      state = {
        ...state,
        allowTeamList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case tournamentConstants.GET_ALLOW_TEAMS_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;

  }
  return state;
};

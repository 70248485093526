/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />

      {/* <AsideMenuItem
        to="/users"
        icon="/media/icons/duotune/communication/com006.svg"
        title="Users"
        fontIcon="bi-layers"
      /> */}

      <AsideMenuItem
        to="/organisers"
        icon="/media/icons/duotune/communication/com005.svg"
        title="Organisers"
        fontIcon="bi-layers"
      />

      {/* <AsideMenuItem
        to="/tournaments"
        icon="/media/icons/duotune/general/gen019.svg"
        title="Tournaments"
        fontIcon="bi-layers"
      />

      <AsideMenuItem
        to="/leaderboard"
        icon="/media/icons/duotune/communication/com009.svg"
        title="Leaderboard"
        fontIcon="bi-layers"
      />

      <AsideMenuItem
        to="/posts"
        icon="/media/icons/duotune/communication/com008.svg"
        title="Posts"
        fontIcon="bi-layers"
      />

      <AsideMenuItemWithSub
        to="/settings"
        title="Settings"
        icon="/media/icons/duotune/general/gen001.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to="/settings/privacy-policy"
          title="Privacy Policy"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/settings/terms-of-use"
          title="Terms of Use"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to="/muthrab-team"
        icon="/media/icons/duotune/communication/com012.svg"
        title="Muthrab Team"
        fontIcon="bi-layers"
      /> */}
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { FcNext ,FcPrevious} from "react-icons/fc"

const PageButtonComponent = (props:any):any=> {
  return(
  <button {...props}>{props.children}</button>
)};


//-----------------------------------------------------main function---------------------------------

const PaginationLayout:React.FC<any> = (props) =>{
  const [visiblePages, setVisiblePages] = useState([]);

  useEffect(() => {
    setVisiblePages(getVisiblePages(null, props.totalPage));
    changePage(props.page + 1);
  }, [props.totalPage]);

  const activePage = props.page + 1;

  const getVisiblePages = function(page:any, total:any):any {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  const filterPages = function(visiblePages:any, totalPagesCount:any):any {
    return visiblePages.filter((page:any) => page <= totalPagesCount);
  };

  const changePage = (page:any) => {
    const activePage = props.page + 1;
    if (page === activePage) {
      return;
    }
    const visiblePages = getVisiblePages(page, props.totalPage);
    setVisiblePages(filterPages(visiblePages, props.totalPage));
    props.onPageChange(page - 1);
  };

  return (
    <Row className="position-absolute bottom-0 end-0 mb-5 pb-5 pe-5">
      <div className="pagination justify-content-end pagination-rounded pb-5">
        <div className={`page-item ${!props.canPreviousPage && "disabled"}`}>
          <PageButtonComponent
            className="page-link"
            onClick={() => {
              if (activePage === 1) return;
              changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            <span className="iconify"  >
            <i className='fas fa-angle-left pt-3' style={{fontSize:"3rem"}} />
            </span>
          </PageButtonComponent>
        </div>
        <div className="page-item d-flex">
          {visiblePages.map((page, index, array) => {
            return (
              <PageButtonComponent
              key={page}
              className={
                activePage === page ? "active btn btn-link fs-1 p-0 mx-5" : "page btn btn-link fs-4 mx-5" 
              }
              onClick={changePage.bind(null, page)}
              >
                {array[index - 1] + 2 < page ? `...${page}` : page}
              </PageButtonComponent>
            );
          })}
        </div>
        <div className={`page-item ${!props.canNextPage && "disabled"}`}>
          <PageButtonComponent
            className="page-link"
            onClick={() => {
              if (activePage === props.pages) return;
              changePage(activePage + 1);
            }}
            disabled={activePage === props.pages}
            >
            <span className="iconify"  >
            <i className='fas fa-angle-right pt-3' style={{fontSize:"3rem"}} />
            </span>
          </PageButtonComponent>
        </div>
      </div>
    </Row>
  );
};

export default PaginationLayout;
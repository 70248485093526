import React from "react";
import { useHistory } from "react-router-dom";
import { KTSVG } from "../../../helpers";

type Props = {
  Color: string;
  className?: string;
  Contain_Name: string;
  Contain_Number: number;
  Link_Redirect: "/dashboard" | "/organisers" | "/users";
};

const MixedWidget13: React.FC<Props> = ({
  Contain_Name,
  className,
  Color,
  Contain_Number,
  Link_Redirect,
}) => {
  const history = useHistory();
  const redirectOn = (links: string) => {
    if (links === "/organisers") {
      history.push({
        pathname: "organisers",
        state: "Organisers",
      });
    } else {
      history.push(links);
    }
  };
  return (
    <div
      className={`p-5 ${className}  card-rounded h-175px   w-175px bg-${Color} hoverable`}
      onClick={() => {
        redirectOn(Link_Redirect);
      }}
    >
      {/* begin::Heading */}
      <div className="d-flex flex-stack">
        <h3 className="m-0 text-white fw-bolder ">{Contain_Name}</h3>
      </div>
      {/* end::Heading */}
      {/* begin::Balance */}
      <div className="d-flex text-center flex-column text-white pt-8">
        {/* <span className='fw-bold fs-7'>You Balance</span> */}

        {Contain_Number > 9999999 ? (
          <span
            className="fw-bolder  pt-1"
            style={{ fontSize: "22px" }}
          >{`${Contain_Number}`}</span>
        ) : Contain_Number > 999999 ? (
          <span
            className="fw-bolder  pt-1"
            style={{ fontSize: "23px" }}
          >{`${Contain_Number}`}</span>
        ) : Contain_Number > 99999 ? (
          <span
            className="fw-bolder  pt-1"
            style={{ fontSize: "27px" }}
          >{`${Contain_Number}`}</span>
        ) : Contain_Number > 9999 ? (
          <span
            className="fw-bolder  pt-1"
            style={{ fontSize: "31px" }}
          >{`${Contain_Number}`}</span>
        ) : Contain_Number > 1000 ? (
          <span
            className="fw-bolder  pt-1"
            style={{ fontSize: "33px" }}
          >{`${Contain_Number}`}</span>
        ) : (
          <span
            className="fw-bolder  pt-1"
            style={{ fontSize: "45px" }}
          >{`${Contain_Number}`}</span>
        )}

        {/* <span className='fw-bolder  pt-1' style={{fontSize:"45px"}} >{`${Contain_Number}`}</span> */}
      </div>
      {/* end::Balance */}
    </div>
  );
};

export { MixedWidget13 };

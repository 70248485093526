import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { useDispatch, useSelector } from "react-redux";
import { MixedWidget13 } from "../../../_metronic/partials/widgets";
import { getdashbord } from "../../../actions";
import { toast } from "react-toastify";

//for color set edit this file::" _variables.custom"

const MessageComponent: FC = () => {
  const dispatch = useDispatch();
  const dashbordData = useSelector((state: any) => state.dashbord);

  useEffect(() => {
    if (dashbordData.statuscode === 0) {
      toast.error(`${dashbordData.message}`);
    } else if (dashbordData.statuscode === 1) {
      toast.success(`${dashbordData.message}`);
    } else {
      console.log("some error on toaster message");
    }
  }, [dashbordData.statuscode]);

  useEffect(() => {
    dispatch(getdashbord());
  }, []);
  return (
    <>
      <div className="container-flued ">
        <div className="row gy-5 g-xl-8 d-flex justify-content-center">
          <div className="d-flex">
            <MixedWidget13
              Color="warning"
              Contain_Name="Users"
              Contain_Number={dashbordData?.users}
              Link_Redirect="/users"
            />
            <MixedWidget13
              className="ms-5"
              Color="success"
              Contain_Name="Organisers"
              Contain_Number={dashbordData?.organisers}
              Link_Redirect="/organisers"
            />
          </div>
          <div className="d-flex">
            <MixedWidget13
              Color="primary"
              Contain_Name="Previous Tournaments"
              Contain_Number={dashbordData?.previous_tournaments}
              Link_Redirect="/organisers"
            />
            <MixedWidget13
              className="ms-5"
              Color="primary"
              Contain_Name="Ongoing Tournaments"
              Contain_Number={dashbordData?.ongoing_tournaments}
              Link_Redirect="/organisers"
            />
            <MixedWidget13
              className="ms-5"
              Color="primary"
              Contain_Name="Upcoming Tournaments"
              Contain_Number={dashbordData?.upcoming_tournaments}
              Link_Redirect="/organisers"
            />
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  );
};

const Messages: FC = () => {
  // const intl = useIntl();
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle> */}
      <MessageComponent />
    </>
  );
};

export default Messages;

import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import * as Yup from "yup";
import clsx from "clsx";
import { adminlogin, login } from "../../../actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state: any) => state.auth);
  const notifyerror = () => toast.error(auth.message);

  useEffect(() => {
    if (auth.authenticate) {
      <Redirect to="/" />;
    } else {
      if (auth.message) {
        if (auth.authenticate) {
          setLoading(false);
        } else {
          setLoading(false);
          notifyerror();
        }
      }
    }
  }, [auth]);

  const isUserAdmin = useMemo(() => {
    if (history?.location?.pathname?.includes("/admin")) {
      return true;
    } else {
      return false;
    }
  }, [history?.location]);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={(values) => {
          setLoading(true);
          setTimeout(() => {
            if (isUserAdmin) {
              dispatch(adminlogin(values.email, values.password));
            } else {
              let formData = new FormData();
              formData.append("email_address", values.email);
              formData.append("password", values.password);
              dispatch(login(formData));
            }
          }, 1000);
        }}
      >
        {(formik) => (
          <Form>
            {/* <div className="text-center mb-10">
              <h1 className="text-dark mb-3">Sign In to Muthrab</h1>
            </div> */}

            {formik.status ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : null}

            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">
                Email
              </label>
              <input
                placeholder="Email"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  { "is-invalid": formik.touched.email && formik.errors.email },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
                type="email"
                name="email"
                autoComplete="off"
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div>

            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex flex-stack mb-2">
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Password
                  </label>

                  <Link
                    to={
                      isUserAdmin
                        ? "/auth/admin/forgot-password"
                        : "/auth/forgot-password"
                    }
                    className="link-primary fs-6 fw-bolder"
                    style={{ marginLeft: "5px" }}
                  >
                    Forgot Password ?
                  </Link>
                </div>
              </div>
              <input
                type="password"
                autoComplete="off"
                placeholder="password"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={!formik.isValid}
              >
                {!loading && <span className="indicator-label">Submit</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../_metronic/layout/core";
import { useDispatch, useSelector } from "react-redux";
import { TablesWidget1 } from "../../_metronic/partials/widgets";
import {
  deleteOrganiser,
  getOrganiserList,
} from "../../actions/organizerConstant";
import { toast } from "react-toastify";
import { KTSVG } from "../../_metronic/helpers";
import { Link } from "react-router-dom";
import { DeletPopup } from "../DeletePopup/DeletePopup";
import { AddEditOrganizerPopup } from "./AddEditOrganizerPopup";
import moment from "moment";
import { IAMGE_BASE_URL } from "../../Configs";

type InerData = {
  email: string;
  is_active: boolean;
  name: string;
  profile_url: string;
  registration_date: string;
  _id: string;
};
type UserAction = {
  organizer: {
    data: [InerData];
    allOrganisers: [];
    message: "";
    status: number;
  };
  type: "";
};

const OrganisersComponent: FC = () => {
  const userData = useSelector((state: UserAction) => state.organizer);
  const dispatch = useDispatch();

  const COLUMNS = [
    {
      Header: "Organisers Name",
      accessor: "name",
      width: 100,
      Cell: ({
        row,
      }: {
        row: { original: { organiser_profile_picture: string; name: string } };
      }) => (
        <div className="d-flex align-items-center">
          <img
            className="me-3"
            src={`${IAMGE_BASE_URL}/${row.original.organiser_profile_picture}`}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event> | any) =>
              (e.target.src = "/media/avatars/blank.png")
            }
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "5px",
              margin: "0",
              padding: "0",
            }}
          />
          <span>{row.original.name}</span>
        </div>
      ),
    },
    {
      Header: "Email Id",
      accessor: "email_address",
      Cell: ({ row }: { row: { values: { email_address: string } } }) =>
        `${row.values.email_address}`,
    },
    {
      Header: "User Id",
      accessor: "_id",
    },
    {
      Header: "Register Date",
      accessor: "created_At",
      Cell: (row: { value: string }) => {
        return moment(row.value).format("D MMM, YYYY - hh:mmA");
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableSortBy: true,
      Cell: ({ row }: { row: { original: { _id: string } } }) => {
        return (
          <>
            {/* <Link
              to={`/users/${row.original._id}`}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              data-bs-toggle="tooltip"
              title="User Details"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen049.svg"
                className="svg-icon-1"
              />
            </Link> */}
            <a
              onClick={() => editOrganizer(row.original)}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              data-bs-toggle="tooltip"
              title="Delete"
            >
              <KTSVG
                path="/media/icons/duotune/art/art005.svg"
                className="svg-icon-1"
              />
            </a>
            <a
              onClick={() => deleteById(row.original._id)}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              data-bs-toggle="tooltip"
              title="Delete"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen027.svg"
                className="svg-icon-1"
              />
            </a>
          </>
        );
      },
    },
  ];

  const [deleteId, setDeleteId] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [addEditOrganizer, setAddEditOrganizer] = useState<boolean>(false);
  const [editOrganizerData, setEditOrganizerData] = useState<object | null>(
    null
  );

  useEffect(() => {
    getOrganisers();
  }, []);

  useEffect(() => {
    if (userData.status == 0) {
      toast.error(userData.message);
    } else if (userData.status == 1) {
      toast.success(userData.message);
      if (show) {
        handleClose();
        getOrganisers();
      }
      if (addEditOrganizer) {
        handleCloseAddEditView();
        getOrganisers();
      }
    } else {
      console.log("");
    }
  }, [userData.status]);

  const editOrganizer = (organiserData: any) => {
    setEditOrganizerData(organiserData);
    setAddEditOrganizer(true);
  };

  const deleteById = (row: string) => {
    setShow(true);
    setDeleteId(row);
  };

  const getOrganisers = () => {
    dispatch(getOrganiserList());
  };

  const handleClose = () => {
    if (deleteId) {
      setDeleteId("");
    }
    setShow(false);
  };
  const handleCloseAddEditView = () => {
    if (editOrganizerData) {
      setDeleteId("");
    }
    setAddEditOrganizer(false);
  };

  const handalonSubmitPopUp = (e: Event) => {
    e.preventDefault();
    dispatch(deleteOrganiser({ organiser_id: deleteId }));
  };

  const handalSubmitOrganizer = () => {
    // handleCloseAddEditView();
    // dispatch(getOrganiserList());
  };

  return (
    <>
      <div className="text-end mb-4">
        <button
          type="button"
          className="btn btn-lg btn-primary"
          data-kt-menu-dismiss="true"
          onClick={() => setAddEditOrganizer(true)}
        >
          Create Organizer
        </button>
      </div>
      <TablesWidget1
        columns={COLUMNS}
        data={userData?.allOrganisers || []}
        isShowPageSize={true}
        classname={"100%"}
      />
      {show && (
        <DeletPopup
          show={show}
          handleClose={handleClose}
          submitPopup={handalonSubmitPopUp}
          data={deleteId}
        />
      )}
      {addEditOrganizer && (
        <AddEditOrganizerPopup
          show={addEditOrganizer}
          handleClose={handleCloseAddEditView}
          submitPopup={handalSubmitOrganizer}
          data={editOrganizerData}
        />
      )}
    </>
  );
};

const Organisers: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "Organisers" })}
      </PageTitle>
      <OrganisersComponent />
    </>
  );
};

export default Organisers;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../_metronic/helpers'
import { IoIosCloseCircleOutline } from 'react-icons/io';

const DeletPopup: React.FC<any> = ({ show, handleClose, submitPopup, data }) => {

  const closepopup = () => {
    handleClose()
  }


  return (
    <Modal
      className='modal-sticky absolute_center modal-sticky-lg modal-sticky-bottom-right '
      id='kt_inbox_compose'
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex='-1'
      show={show}
      animation={true}
    >
      <div className='modal-content'>
        {/* begin::Form */}
        <form id='kt_inbox_compose_form' onSubmit={(e) => submitPopup(e)} >
          {/*begin::Header*/}
          <div className='px-5 pt-5 row '>
            <div className='d-flex justify-content-end ms-2'>
              {/*begin::Close*/}
              <div
                className='btn btn-icon btn-sm btn-outline-danger ms-2'
                data-bs-dismiss='modal'
                data-bs-toggle="tooltip"
                title="Close"
                onClick={() => closepopup()}
              >
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
              {/*end::Close*/}
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className='d-block'>
            {/*begin::To*/}
            <div className=' py-5 card-toolbar'>
              <div className="row rounded-circle border-danger">
                <div className="col d-flex justify-content-center">
                  <IoIosCloseCircleOutline size={100} className="text-danger my-5" />
                </div>
              </div>
              <div className="row">
                <p className='fw-normal text-center my-5' style={{ fontSize: "30px" }} >Are You Sure?</p>
              </div>
              <div className='row' >
                <div className='col-12 align-center ' >
                  <h3 className='text-muted fw-normal my-5' style={{ textAlign: "center" }}>{`Do you really want to delete these records?`}</h3>
                </div>
              </div>
            </div>
            {/*end::To*/}

            {/*begin::Subject*/}

            {/*end::Subject*/}
          </div>
          {/*end::Body*/}

          {/*begin::Footer*/}
          <div className=' py-5 ps-8 pe-5 '>
            {/*begin::Actions*/}
            <div className='row justify-content-center mb-15'>
              {/*begin::Send*/}
              <div className="col d-flex  justify-content-end ">
                <button type="button" className='btn btn-secondary me-4 px-10 ' onClick={() => { handleClose() }}>Cancel</button>
              </div>
              <div className="col">
                <button type="submit" className='btn btn-danger me-4 px-10'>Delete</button>
              </div>
              {/*end::Send*/}
            </div>
            {/*end::Actions*/}
          </div>
          {/*end::Footer*/}
        </form>
        {/*end::Form*/}
      </div >
    </Modal >
  )
}

export { DeletPopup }

import { authConstants } from "../actions/constants";

const initialState = {
  data: {
    email: "",
    token: null,
    user_id: "",
  },
  userRole: "",
  authenticate: false,
  authenticating: false,
  message: "",
  successMessage: "",
  status: 2,
};

export default (
  state = initialState,
  action: {
    type: string;
    payload: {
      data: { email: string; token: string; user_id: string } | any;
      userrole: string;
      message: string;
      status: number;
    };
  }
) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      state = {
        ...state,
        status: 2,
        authenticating: true,
      };
      break;
    case authConstants.LOGIN_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        userRole: action.payload.userrole,
        authenticate: true,
        authenticating: false,
        message: action.payload.message,
        status: action.payload.status,
      };
      break;
    case authConstants.LOGIN_FAILURE:
      state = {
        ...state,
        data: action.payload.data,
        authenticate: false,
        authenticating: false,
        message: action.payload.message,
      };
      break;
    case authConstants.LOGOUT_REQUEST:
      state = {
        ...state,
      };
      break;
    case authConstants.LOGOUT_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
      };
      break;
  }
  return state;
};

import clsx from "clsx";
import { Formik, Form } from "formik";
import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryList,
  getFormatList,
  getlevelList,
  getSportsList,
  getTypeList,
} from "../../../../actions/commoConstant";
import CreateTournamentSecondForm from "./create-tournament-second-form";
import Geocode from "react-geocode";
import {
  createTournamentAction,
  getAllowTeams,
  getAllUserAction,
} from "../../../../actions/tournamentsConstants";
import Country from "../../../../country.json";
import { tournamentConstants } from "../../../../actions";

const tournamentSchema = Yup.object().shape({
  tournament_name: Yup.string().required("Tournament name is required"),
  description: Yup.string().required("Description is required"),
  sports: Yup.string().required("Sports is required"),
  category: Yup.string().required("Category is required"),
  type: Yup.string().required("tournament type is required"),
  format: Yup.string().required("format type is required"),
  number_of_player: Yup.string().required("Number of player is required"),
  tournament_level: Yup.string().required("tournament level is required"),
  start_date: Yup.string().required("start date is required"),
  start_time: Yup.string().required("start time is required"),
  end_date: Yup.string().required("End date is required"),
});

const inits = {
  tournament_name: "",
  description: "",
  sports: "",
  category: "",
  type: "",
  format: "",
  number_of_player: "",
  tournament_level: "",
  start_date: "",
  start_time: "",
  end_date: "",
};
const CreateTournament: FC = () => {
  const [initValues] = useState<any>(inits);
  // Geocode.setApiKey("AIzaSyC43U2-wqXxYEk1RBrTLdkYt3aDoOxO4Fw");
  const [isOpenSecondForm, setIsOpenSecondForm] = useState<boolean>(false);
  const [firstFormDetail, setFirstFormDetail] = useState<any>(null);
  const commonData = useSelector((state: any) => state.common);
  const tournamentData = useSelector((state: any) => state.tournament);
  const dispatch = useDispatch();

  useEffect(() => {
    if (commonData?.typeList?.length === 0) {
      dispatch(getTypeList());
    }
    if (commonData?.formatList?.length === 0) {
      dispatch(getFormatList());
    }
    if (commonData?.sportsList?.length === 0) {
      dispatch(getSportsList());
    }
    if (commonData?.categoryList?.length === 0) {
      dispatch(getCategoryList());
    }
    if (commonData?.lavelList?.length === 0) {
      dispatch(getlevelList());
    }
    if (tournamentData?.userList?.length === 0) {
      dispatch(getAllUserAction());
    }

    dispatch({ type: tournamentConstants.GET_ALLOW_TEAMS_REQUEST });
  }, []);

  const handleChangeType = (e: any, formik: any) => {
    if (e.target.value) {
      formik.setFieldValue("type", e.target.value, false);
      dispatch(getAllowTeams(e.target.value));
      formik.setFieldValue("number_of_player", "", false);
    }
  };

  const handleCreateTournament = (values: any) => {
    const finalTournamentData = { ...values, ...firstFormDetail };
    const inviteMemberList = values?.invite_members?.map((item: any) => {
      return item.value;
    });
    // const latlng = Geocode.fromAddress(
    //   "70 Cedars Rd, London SW4 0QB, United Kingdom"
    // ).then(
    //   (response) => {
    //     const { lat, lng } = response.results[0].geometry.location;
    //     console.log(lat, lng);
    //     return { lat, lng };
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
    let formData = new FormData();
    formData.append("tournament_name", finalTournamentData?.tournament_name);
    formData.append("description", finalTournamentData?.description);
    formData.append("sports", finalTournamentData?.sports);
    formData.append("category", "62e79556623eb564ba417675");
    formData.append("format", finalTournamentData?.format);
    formData.append(
      "number_of_team",
      finalTournamentData?.number_of_player &&
        parseInt(finalTournamentData?.number_of_player)
    );
    formData.append("type", finalTournamentData?.type);
    formData.append("tournament_level", finalTournamentData?.tournament_level);
    formData.append("start_date", finalTournamentData?.start_date);
    formData.append("start_time", finalTournamentData?.start_time);
    formData.append("end_date", finalTournamentData?.end_date);
    formData.append(
      "venue",
      JSON.stringify({
        country: finalTournamentData?.country,
        city: finalTournamentData?.city,
        venue: finalTournamentData?.venue,
      })
    );
    formData.append(
      "country_currency",
      JSON.stringify({
        country_name: finalTournamentData?.country_name,
        currency_symbol: "$",
      })
    );
    formData.append("prize", JSON.stringify(finalTournamentData?.prize));
    formData.append(
      "registration_info",
      JSON.stringify({
        registration_fee: finalTournamentData?.registration_fee,
        registration_amount: finalTournamentData?.registration_amount,
      })
    );
    formData.append(
      "tournament_type",
      JSON.stringify({
        type: finalTournamentData?.tournament_type,
        invite_members: inviteMemberList,
      })
    );
    formData.append("status", "1");
    formData.append("tournament_image", finalTournamentData?.tournament_image);
    formData.append(
      "registration_close_date",
      finalTournamentData?.register_end_date
    );
    formData.append(
      "location",
      JSON.stringify({
        type: "Point",
        coordinates: [-110.8571443, 32.4586858],
      })
    );

    dispatch(createTournamentAction(formData));
  };

  return (
    <React.Fragment>
      <div className="">
        {/** create tournament */}
        <div className="flex-row-fluid">
          {!isOpenSecondForm && (
            <Formik
              initialValues={firstFormDetail || initValues}
              validationSchema={tournamentSchema}
              onSubmit={(values) => {
                console.log(values);
                setFirstFormDetail(values);
                setIsOpenSecondForm(true);
              }}
            >
              {(formik) => (
                <Form className="form" noValidate id="kt_modal_create_app_form">
                  {/** Tournament Info. */}
                  <div
                    className="current card mb-6"
                    data-kt-stepper-element="content"
                  >
                    <div className="row">
                      <div className="col">
                        <h3 className="m-0 px-5 fs-1 py-4">Tournament Info.</h3>
                      </div>
                    </div>

                    <div className=" p-5">
                      {/** Tournament Name */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Tournament Name</h4>
                        </div>
                        <div className="col-9">
                          <input
                            type="text"
                            {...formik.getFieldProps("tournament_name")}
                            className={clsx(
                              "form-control form-control-md form-control-solid",
                              {
                                "is-invalid":
                                  formik.touched.tournament_name &&
                                  formik.errors.tournament_name,
                              },
                              {
                                "is-valid":
                                  formik.touched.tournament_name &&
                                  !formik.errors.tournament_name,
                              }
                            )}
                            // className="form-control form-control-md form-control-solid"
                            placeholder="Enter Tournament Name"
                            name="tournament_name"
                          />
                          {formik.touched.tournament_name &&
                            formik.errors.tournament_name && (
                              <div className="fv-plugins-message-container">
                                <span role="alert">
                                  {formik.errors.tournament_name}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      {/** Description */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Description</h4>
                        </div>

                        <div className="col-9">
                          <input
                            type="text"
                            {...formik.getFieldProps("description")}
                            className={clsx(
                              "form-control form-control-md form-control-solid",
                              {
                                "is-invalid":
                                  formik.touched.description &&
                                  formik.errors.description,
                              },
                              {
                                "is-valid":
                                  formik.touched.description &&
                                  !formik.errors.description,
                              }
                            )}
                            placeholder="Enter Description"
                            name="description"
                          />
                          {formik.touched.description &&
                            formik.errors.description && (
                              <div className="fv-plugins-message-container">
                                <span role="alert">
                                  {formik.errors.description}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      {/** Sport */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Sport</h4>
                        </div>

                        <div className="col-9">
                          <select
                            {...formik.getFieldProps("sports")}
                            aria-label="Select Padel"
                            data-control="select2"
                            data-placeholder="sports"
                            className={clsx(
                              "form-select form-select-md form-select-solid",
                              {
                                "is-invalid":
                                  formik.touched.sports && formik.errors.sports,
                              },
                              {
                                "is-valid":
                                  formik.touched.sports &&
                                  !formik.errors.sports,
                              }
                            )}
                          >
                            {commonData?.sportsList?.length > 0 ? (
                              <>
                                <option value="" disabled>
                                  Select sports
                                </option>
                                {commonData?.sportsList?.map((item: any) => (
                                  <option key={item?._id} value={item?._id}>
                                    {item?.sport_name}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <option disabled value="0">
                                No Data found
                              </option>
                            )}
                          </select>
                          {formik.touched.sports && formik.errors.sports && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.sports}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/** Gender */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Gender</h4>
                        </div>

                        <div className="col-9">
                          <select
                            {...formik.getFieldProps("category")}
                            aria-label="Select category"
                            data-control="select2"
                            data-placeholder="category"
                            className={clsx(
                              "form-select form-select-md form-select-solid",
                              {
                                "is-invalid":
                                  formik.touched.category &&
                                  formik.errors.category,
                              },
                              {
                                "is-valid":
                                  formik.touched.category &&
                                  !formik.errors.category,
                              }
                            )}
                          >
                            {commonData?.categoryList?.length > 0 ? (
                              <>
                                <option value="" disabled>
                                  Select category
                                </option>
                                {commonData?.categoryList?.map((item: any) => (
                                  <option key={item?._id} value={item?._id}>
                                    {item?.category_name}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <option disabled value="0">
                                No Data found
                              </option>
                            )}
                          </select>
                          {formik.touched.category && formik.errors.category && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.category}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/** Type */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Type</h4>
                        </div>

                        <div className="col-9">
                          <select
                            {...formik.getFieldProps("type")}
                            aria-label="Select type"
                            data-control="select2"
                            data-placeholder="type"
                            onChange={(e) => handleChangeType(e, formik)}
                            className={clsx(
                              "form-select form-select-md form-select-solid",
                              {
                                "is-invalid":
                                  formik.touched.type && formik.errors.type,
                              },
                              {
                                "is-valid":
                                  formik.touched.type && !formik.errors.type,
                              }
                            )}
                          >
                            {commonData?.typeList?.length > 0 ? (
                              <>
                                <option value="" disabled>
                                  Select type
                                </option>
                                {commonData?.typeList?.map((item: any) => (
                                  <option key={item?._id} value={item?._id}>
                                    {item?.type_name}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <option disabled value="0">
                                No Data found
                              </option>
                            )}
                          </select>
                          {formik.touched.type && formik.errors.type && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.type}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/** Number of Player */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">
                            Number of Player
                          </h4>
                        </div>

                        <div className="col-9">
                          <select
                            {...formik.getFieldProps("number_of_player")}
                            aria-label="Select teams"
                            data-control="select2"
                            data-placeholder="number of player"
                            className={clsx(
                              "form-select form-select-md form-select-solid",
                              {
                                "is-invalid":
                                  formik.touched.number_of_player &&
                                  formik.errors.number_of_player,
                              },
                              {
                                "is-valid":
                                  formik.touched.number_of_player &&
                                  !formik.errors.number_of_player,
                              }
                            )}
                          >
                            {tournamentData?.allowTeamList?.length > 0 ? (
                              <>
                                <option value="" disabled>
                                  Select number of teams
                                </option>
                                {tournamentData?.allowTeamList?.map(
                                  (item: any) => (
                                    <option key={item?._id} value={item?._id}>
                                      {item?.no_of_teams}
                                    </option>
                                  )
                                )}
                              </>
                            ) : (
                              <option disabled value="0">
                                No Data found
                              </option>
                            )}
                          </select>
                          {formik.touched.number_of_player &&
                            formik.errors.number_of_player && (
                              <div className="fv-plugins-message-container">
                                <span role="alert">
                                  {formik.errors.number_of_player}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      {/** Format  */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Format</h4>
                        </div>

                        <div className="col-9">
                          <select
                            {...formik.getFieldProps("format")}
                            aria-label="Select format"
                            data-control="select2"
                            data-placeholder="format"
                            className={clsx(
                              "form-select form-select-md form-select-solid",
                              {
                                "is-invalid":
                                  formik.touched.format && formik.errors.format,
                              },
                              {
                                "is-valid":
                                  formik.touched.format &&
                                  !formik.errors.format,
                              }
                            )}
                          >
                            {commonData?.formatList?.length > 0 ? (
                              <>
                                <option value="" disabled>
                                  Select format
                                </option>
                                {commonData?.formatList?.map((item: any) => (
                                  <option key={item?._id} value={item?._id}>
                                    {item?.format_name}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <option disabled value="0">
                                No Data found
                              </option>
                            )}
                          </select>
                          {formik.touched.format && formik.errors.format && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.format}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/** Level of the Tournament  */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">
                            Level of the Tournament
                          </h4>
                        </div>

                        <div className="col-9">
                          <select
                            {...formik.getFieldProps("tournament_level")}
                            aria-label="Select tournament level"
                            data-control="select2"
                            data-placeholder="tournament level"
                            className={clsx(
                              "form-select form-select-md form-select-solid",
                              {
                                "is-invalid":
                                  formik.touched.tournament_level &&
                                  formik.errors.tournament_level,
                              },
                              {
                                "is-valid":
                                  formik.touched.tournament_level &&
                                  !formik.errors.tournament_level,
                              }
                            )}
                          >
                            {commonData?.lavelList?.length > 0 ? (
                              <>
                                <option value="" disabled>
                                  Select tournament level
                                </option>
                                {commonData?.lavelList?.map((item: any) => (
                                  <option key={item?._id} value={item?._id}>
                                    {item?.level_name}
                                  </option>
                                ))}
                              </>
                            ) : (
                              <option disabled value="0">
                                No Data found
                              </option>
                            )}
                          </select>
                          {formik.touched.tournament_level &&
                            formik.errors.tournament_level && (
                              <div className="fv-plugins-message-container">
                                <span role="alert">
                                  {formik.errors.tournament_level}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      {/** Level of the Tournament  */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Start Date/Time</h4>
                        </div>

                        <div className="col-9">
                          <div className="row">
                            <div className="col-6 mb-5">
                              <input
                                type="date"
                                {...formik.getFieldProps("start_date")}
                                className={clsx(
                                  "form-control form-control-md form-control-solid",
                                  {
                                    "is-invalid":
                                      formik.touched.start_date &&
                                      formik.errors.start_date,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.start_date &&
                                      !formik.errors.start_date,
                                  }
                                )}
                                placeholder=""
                                name="start_date"
                              />
                              {formik.touched.start_date &&
                                formik.errors.start_date && (
                                  <div className="fv-plugins-message-container">
                                    <span role="alert">
                                      {formik.errors.start_date}
                                    </span>
                                  </div>
                                )}
                            </div>
                            <div className="col-6 mb-5">
                              <input
                                type="time"
                                {...formik.getFieldProps("start_time")}
                                className={clsx(
                                  "form-control form-control-md form-control-solid",
                                  {
                                    "is-invalid":
                                      formik.touched.start_time &&
                                      formik.errors.start_time,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.start_time &&
                                      !formik.errors.start_time,
                                  }
                                )}
                                placeholder=""
                                name="start_time"
                              />
                              {formik.touched.start_time &&
                                formik.errors.start_time && (
                                  <div className="fv-plugins-message-container">
                                    <span role="alert">
                                      {formik.errors.start_time}
                                    </span>
                                  </div>
                                )}
                            </div>
                            <div className="col-12 mb-5">
                              <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Require Players to check in
                                </label>
                              </div>
                            </div>
                            <div className="d-flex align-center justify-content-between">
                              <p className="m-0">Check-in opens</p>
                              <select
                                {...formik.getFieldProps(
                                  "before_check_in_hour"
                                )}
                                name="before_check_in_hour"
                                aria-label="Select Checkin hours"
                                data-control="select2"
                                data-placeholder="Checkin hours"
                                className="form-select form-select-md form-select-solid"
                              >
                                <option value="">Select check-in hour</option>
                                <option value="2 Hours">2 Hours</option>
                                <option value="3 Hours">3 Hours</option>
                              </select>
                              <p className="m-2">before start time</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/** End Date  */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">End Date</h4>
                        </div>

                        <div className="col-9">
                          <input
                            type="date"
                            {...formik.getFieldProps("end_date")}
                            className={clsx(
                              "form-control form-control-md form-control-solid",
                              {
                                "is-invalid":
                                  formik.touched.end_date &&
                                  formik.errors.end_date,
                              },
                              {
                                "is-valid":
                                  formik.touched.end_date &&
                                  !formik.errors.end_date,
                              }
                            )}
                            placeholder=""
                            name="end_date"
                          />
                          {formik.touched.end_date && formik.errors.end_date && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.end_date}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/** Venue */}
                  <div
                    className="current card mb-6"
                    data-kt-stepper-element="content"
                  >
                    <div className="row">
                      <div className="col">
                        <h3 className="m-0 px-5 fs-1 py-4">Venue</h3>
                      </div>
                    </div>

                    <div className=" p-5">
                      {/** Country */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Country</h4>
                        </div>

                        <div className="col-9">
                          <select
                            {...formik.getFieldProps("country")}
                            name="country"
                            aria-label="Select Country"
                            data-control="select2"
                            data-placeholder="country"
                            className={clsx(
                              "form-select form-select-md form-select-solid",
                              {
                                "is-invalid":
                                  formik.touched.country &&
                                  formik.errors.country,
                              },
                              {
                                "is-valid":
                                  formik.touched.country &&
                                  !formik.errors.country,
                              }
                            )}
                          >
                            <option value="">Select Country</option>
                            {Country.map((item: any, i: any) => (
                              <option key={i} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.country && formik.errors.country && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.country}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/** City */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">City</h4>
                        </div>

                        <div className="col-9">
                          <select
                            {...formik.getFieldProps("city")}
                            name="city"
                            aria-label="Select city"
                            data-control="select2"
                            data-placeholder="city"
                            className={clsx(
                              "form-select form-select-md form-select-solid",
                              {
                                "is-invalid":
                                  formik.touched.city && formik.errors.city,
                              },
                              {
                                "is-valid":
                                  formik.touched.city && !formik.errors.city,
                              }
                            )}
                          >
                            <option value="">Select City</option>
                            <option value="Landon">Landon</option>
                            <option value="Brimphton">Brimphton</option>
                          </select>
                          {formik.touched.city && formik.errors.city && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.city}</span>
                            </div>
                          )}
                        </div>
                      </div>

                      {/** Address */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Address</h4>
                        </div>

                        <div className="col-9">
                          <textarea
                            {...formik.getFieldProps("venue")}
                            name="venue"
                            className={clsx(
                              "form-control form-control-md form-control-solid",
                              {
                                "is-invalid":
                                  formik.touched.venue && formik.errors.venue,
                              },
                              {
                                "is-valid":
                                  formik.touched.venue && !formik.errors.venue,
                              }
                            )}
                            placeholder="Add address"
                          />
                          {formik.touched.venue && formik.errors.venue && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">{formik.errors.venue}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/** Tournament Image */}
                  <div
                    className="current card mb-6"
                    data-kt-stepper-element="content"
                  >
                    <div className="row">
                      <div className="col">
                        <h3 className="m-0 px-5 fs-1 py-4">Tournament Image</h3>
                      </div>
                    </div>
                    <div className="p-5">
                      {/** Upload Image */}
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Upload Image</h4>
                        </div>

                        <div className="col-9">
                          <input
                            type="file"
                            name="tournament_image"
                            className="form-control form-control-md form-control-solid"
                            placeholder="Lenght"
                            onChange={(event: any) => {
                              formik.setFieldValue(
                                "tournament_image",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary">
                      Next <i className="bi bi-arrow-right fs-4 me-2"></i>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {isOpenSecondForm && (
            <CreateTournamentSecondForm
              handleOpenSecondForm={() => setIsOpenSecondForm(false)}
              handleSubmitForm={(value: any) => handleCreateTournament(value)}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateTournament;

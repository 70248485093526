import { organizerConstants } from "../actions/constants";

const initialState = {
  allOrganisers: [],
  userdetail: [{}],
  message: "",
  status: 2,
  organizerAddEditStatus: 2,
};

type InerData = [
  {
    email: string;
    is_active: boolean;
    name: string;
    profile_url: string;
    registration_date: string;
    _id: string;
  }
];
type UserAction = {
  payload: {
    data: InerData | any;
    message: "";
    statuscode: number;
  };
  type: "";
};

export default (state = initialState, action: UserAction) => {
  switch (action.type) {
    case organizerConstants.CREATE_ORGANISER_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case organizerConstants.CREATE_ORGANISER_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        status: action.payload.statuscode,
      };
      break;
    case organizerConstants.CREATE_ORGANISER_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        status: 0,
      };
      break;

    case organizerConstants.EDIT_ORGANISER_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case organizerConstants.EDIT_ORGANISER_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        status: action.payload.statuscode,
      };
      break;
    case organizerConstants.EDIT_ORGANISER_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        status: 0,
      };
      break;

    case organizerConstants.GET_ORGANISER_REQUEST:
      state = {
        ...state,
      };
      break;
    case organizerConstants.GET_ORGANISER_SUCCESS:
      state = {
        ...state,
        allOrganisers: action.payload.data,
        message: action.payload.message,
        status: action.payload.statuscode,
      };
      break;
    case organizerConstants.GET_ORGANISER_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        status: 0,
      };
      break;

    case organizerConstants.DELETE_USERS_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case organizerConstants.DELETE_USERS_SUCCESS:
      state = {
        ...state,
        allOrganisers: state.allOrganisers.filter(
          (item, index: any) => index !== action.payload
        ),
        message: action.payload.message,
        status: action.payload.statuscode,
      };
      break;
    case organizerConstants.DELETE_USERS_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        status: 0,
      };
      break;

    case organizerConstants.GET_USER_DETAIL_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case organizerConstants.GET_USER_DETAIL_SUCCESS:
      state = {
        ...state,
        userdetail: action.payload.data,
        message: action.payload.message,
        status: action.payload.statuscode,
      };
      break;
    case organizerConstants.GET_USER_DETAIL_FAILURE:
      state = {
        ...state,
        message: action.payload.message,
        status: 0,
      };
      break;
  }
  return state;
};

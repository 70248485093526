import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Messages from "../containers/organiserComponents/Messages";
import { FallbackView } from "../_metronic/partials";
import Tournaments from "../containers/organiserComponents/Tournaments";
import CreateTournament from "../containers/organiserComponents/Tournaments/create-tournament/create-tournament-first-form";
import Details from "../containers/organiserComponents/Tournaments/Details";

export function OrganiserPrivateRoute() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path="/messages" component={Messages} />
        <Route exact path={["/", "/tournaments"]} component={Tournaments} />
        <Route exact path={"/tournaments/add"} component={CreateTournament} />
        <Route exact path={"/tournaments/details"} component={Details} />
      </Switch>
    </Suspense>
  );
}

import React, { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../actions";
import { VerifyOTP } from "./VerifyOTP";

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

const initialValues = {
  email: "",
};

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [email, setEmail] = useState<any>("");
  const history = useHistory();
  const [OTPsend, setOTPsend] = useState<any>(false);
  const dispatch = useDispatch();

  const isUserAdmin = useMemo(() => {
    if (history?.location?.pathname?.includes("/admin")) {
      return true;
    } else {
      return false;
    }
  }, [history?.location]);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setEmail(values.email);
      let formData = new FormData();
      formData.append("email_address", values.email);
      formData.append("userrole", isUserAdmin ? "admin" : "organiser");
      dispatch(
        forgotPassword(formData, (res: any) => {
          if (res?.statuscode === 1) {
            setOTPsend(true);
          }
        })
      );
    },
  });

  return (
    <>
      {!OTPsend ? (
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate
          id="kt_login_password_reset_form"
          onSubmit={formik.handleSubmit}
        >
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Forgot Password ?</h1>
            <div className="text-gray-400 fw-bold fs-4">
              Enter your registered email below to receive password reset
              Instruction.
            </div>
          </div>
          {hasErrors === true && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">
                Sorry, looks like there are some errors detected, please try
                again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className="mb-10 bg-light-info p-8 rounded">
              <div className="text-info">
                Sent password reset. Please check your email
              </div>
            </div>
          )}

          <>
            <div className="fv-row mb-10">
              <label className="form-label fw-bolder text-gray-900 fs-6">
                Email
              </label>
              <input
                type="email"
                placeholder=""
                autoComplete="off"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid": formik.touched.email && formik.errors.email,
                  },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex flex-wrap justify-content-center mb-5 pb-lg-0">
              <button
                type="submit"
                id="kt_password_reset_submit"
                className="btn btn-lg btn-primary fw-bolder me-4"
              >
                <span className="indicator-label">Submit</span>
                {loading && (
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_password_reset_form_cancel_button"
                  className="btn btn-lg btn-light-primary fw-bolder"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>
            </div>
            <div className="text-gray-400 text-center mt-2 fw-bold fs-6">
              Did not receive the email ? Check Your Spam Filter
            </div>
          </>
        </form>
      ) : (
        <VerifyOTP email={email} isUserAdmin={isUserAdmin} />
      )}
    </>
  );
}

import { API_CONST, ENDPOINT } from "../Configs";
import { STATUS_CODE } from "../helpers/Constants";
import { organizerConstants } from "./constants";
import api_helper from "../Configs/API_helper";
const api = new api_helper();

export const createOrganiser = (organiserData: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: organizerConstants.CREATE_ORGANISER_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.CREATEORGANISER}`, {
      data: organiserData,
    });
    const { data, statuscode, message } = response.data;
    if (response.status === 200) {
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: organizerConstants.CREATE_ORGANISER_SUCCESS,
          payload: {
            data,
            statuscode,
            message,
          },
        });
      } else {
        dispatch({
          type: organizerConstants.CREATE_ORGANISER_FAILURE,
          payload: {
            message,
            statuscode,
            error: response.data.error,
          },
        });
      }
    }
  };
};

export const editOrganiser = (organiserData: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: organizerConstants.EDIT_ORGANISER_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.EDITORGANISER}`, {
      data: organiserData,
    });
    const { data, statuscode, message } = response.data;
    if (response.status === 200) {
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: organizerConstants.EDIT_ORGANISER_SUCCESS,
          payload: {
            data,
            statuscode,
            message,
          },
        });
      } else {
        dispatch({
          type: organizerConstants.EDIT_ORGANISER_FAILURE,
          payload: {
            message,
            statuscode,
            error: response.data.error,
          },
        });
      }
    }
  };
};

export const getOrganiserList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: organizerConstants.GET_ORGANISER_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.ORGANISERLIST}`);
    const { data, statuscode, message } = response.data;
    if (response.status === 200) {
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: organizerConstants.GET_ORGANISER_SUCCESS,
          payload: {
            data,
            statuscode,
            message,
          },
        });
      } else {
        dispatch({
          type: organizerConstants.GET_ORGANISER_FAILURE,
          payload: {
            message,
            statuscode,
            error: response.data.error,
          },
        });
      }
    }
  };
};

export const deleteOrganiser = (deleteDetail: any) => {
  return async (dispatch: Function) => {
    dispatch({ type: organizerConstants.DELETE_USERS_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.DELETEORGANISER}`, {
      data: deleteDetail,
    });
    const { data, message, statuscode } = response.data;
    if (response.status === 200) {
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: organizerConstants.DELETE_USERS_SUCCESS,
          payload: {
            data,
            message,
            statuscode,
          },
        });
      } else {
        dispatch({
          type: organizerConstants.DELETE_USERS_FAILURE,
          payload: {
            message,
            statuscode,
            error: response.data.error,
          },
        });
      }
    }
  };
};

export const getuserDetail = (id: string) => {
  return async (dispatch: Function) => {
    // dispatch({ type: organizerConstants.GET_USER_DETAIL_REQUEST });
    // const response = await api.post(`${API_CONST}${ENDPOINT.USERS}/${id}`);
    // const { data, status, message } = response.data;
    // if (response.status === 200) {
    //   if (status === STATUS_CODE.SUCCESS) {
    //     dispatch({
    //       type: organizerConstants.GET_USER_DETAIL_SUCCESS,
    //       payload: {
    //         data, message, status
    //       },
    //     });
    //   } else {
    //     dispatch({
    //       type: organizerConstants.GET_USER_DETAIL_FAILURE,
    //       payload: {
    //         status, message,
    //         error: response.data.error,
    //       },
    //     });
    //   }
    // } else {
    //   if (response.status === 400) {
    //     dispatch({
    //       type: organizerConstants.GET_USER_DETAIL_FAILURE,
    //       payload: {
    //         status, message,
    //         error: response.data.error,
    //       },
    //     });
    //   }
    // }
  };
};

/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuOrganiser() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/tournaments"
        icon="/media/icons/duotune/art/art002.svg"
        title="Tournaments"
        fontIcon="bi-app-indicator"
      />

      <AsideMenuItem
        to="/messages"
        icon="/media/icons/duotune/communication/com005.svg"
        title="Messages"
        fontIcon="bi-layers"
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../actions";
import { toast } from "react-toastify";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(8, "Maximum 8 symbols")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(8, "Maximum 8 symbols")
    .required("Confirm Password is required"),
});

const initialValues = {
  password: "",
  confirmPassword: "",
};

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [userdetail, setUserDetail] = useState<any>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (history?.location?.state) {
      setUserDetail(history?.location?.state);
    } else {
      history.push("/");
    }
    window.history.replaceState({}, document.title);
  }, [history?.location]);

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let formData = new FormData();
      formData.append("email_address", userdetail?.email);
      formData.append("password", values.password);
      formData.append(
        "userrole",
        userdetail?.isUserAdmin ? "admin" : "organiser"
      );
      setLoading(true);
      setHasErrors(undefined);
      dispatch(
        resetPassword(formData, (res: any) => {
          setLoading(false);
          if (res?.statuscode === 1) {
            toast.success(`${res.message}`);
            history.push(userdetail?.isUserAdmin ? "/auth/admin/login" : "/");
          } else {
            toast.error(`${res.message}`);
          }
        })
      );
    },
  });
  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Reset Password </h1>
          <div className="text-gray-400 fw-bold fs-4">
            Your new password Must be different From your previous password.
          </div>
        </div>

        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Sorry, looks like there are some errors detected, please try
              again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">
              Password reset. Please try to login again
            </div>
          </div>
        )}
        {/* end::Title */}

        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Password
          </label>
          <input
            type="password"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Confirm Password
          </label>
          <input
            type="password"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("confirmPassword")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              {
                "is-invalid":
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword,
              },
              {
                "is-valid":
                  formik.touched.confirmPassword &&
                  !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            <span className="indicator-label">Reset Password</span>
            {loading && (
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{" "}
        </div>
      </form>
    </>
  );
}

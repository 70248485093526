import clsx from "clsx";
import {
  Formik,
  Form,
  FormikValues,
  Field,
  ErrorMessage,
  FieldArray,
} from "formik";
import React, { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import Country from "../../../../country.json";

const inits = {
  prize: [{ prize_name: "", prize_amount: "" }],
};

const CreateTournamentSecondForm: FC<any> = (props: any) => {
  // const [currentSchema, setCurrentSchema] = useState([]);
  const [initValues] = useState<any>(inits);
  const [secondFormDetail, setSecondFormDetail] = useState<any>(null);
  const tournamentData = useSelector((state: any) => state.tournament);

  const handleAddPrize = (e: any, formik: any) => {
    const prize = [...formik.values.prize];
    // const previousNumber = parseInt(field.value || "0");
    // if (previousNumber < numberOfTickets) {
    //   for (let i = previousNumber; i < numberOfTickets; i++) {
    prize.push({ prize_name: "", prize_amount: "" });
    //   }
    // }
    //  else {
    //   for (let i = previousNumber; i >= numberOfTickets; i--) {
    //     prize.splice(i, 1);
    //   }
    // }
    formik.setValues({ ...formik.values, prize });
  };

  const userListOption = useMemo(() => {
    if (tournamentData?.userList?.length > 0) {
      return tournamentData?.userList?.map((item: any) => {
        return {
          ...item,
          label: `${item.first_name} ${item.last_name}`,
          value: item._id,
        };
      });
    } else {
      return [];
    }
  }, [tournamentData?.userList]);

  return (
    <React.Fragment>
      <div className="">
        {/** create tournament */}
        <div className="flex-row-fluid">
          <Formik
            // validationSchema={currentSchema}
            initialValues={secondFormDetail || initValues}
            onSubmit={(values) => {
              console.log("values", values);
              setSecondFormDetail(values);
              props.handleSubmitForm(values);
            }}
          >
            {(formik) => (
              <Form className="form" noValidate id="kt_modal_create_app_form">
                {/** Select country for currency */}
                <div
                  className="current card mb-6"
                  data-kt-stepper-element="content"
                >
                  <div className="row">
                    <div className="col">
                      <h3 className="m-0 px-5 fs-1 py-4">
                        Select country for currency
                      </h3>
                    </div>
                  </div>

                  <div className=" p-5">
                    {/** Country */}
                    <div className="row mb-6">
                      <div className="col-3 m-auto">
                        <h4 className="fw-bold text-dark">Country</h4>
                      </div>

                      <div className="col-9">
                        <div className="row">
                          <div className="col-6">
                            <select
                              {...formik.getFieldProps("country_name")}
                              name="country_name"
                              aria-label="Select Padel"
                              data-control="select2"
                              data-placeholder="country name"
                              className={clsx(
                                "form-select form-select-md form-select-solid",
                                {
                                  "is-invalid":
                                    formik.touched.country_name &&
                                    formik.errors.country_name,
                                },
                                {
                                  "is-valid":
                                    formik.touched.country_name &&
                                    !formik.errors.country_name,
                                }
                              )}
                            >
                              {Country.map((item: any, i: any) => (
                                <option key={i} value={item.name}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {formik.touched.country_name &&
                              formik.errors.country_name && (
                                <div className="fv-plugins-message-container">
                                  <span role="alert">
                                    {formik.errors.country_name}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="col-1">
                            {/* <input
                              type="text"
                              className="form-control form-control-md form-control-solid"
                              placeholder="$"
                              name="name"
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/** Prize */}
                <div
                  className="current card mb-6"
                  data-kt-stepper-element="content"
                >
                  <div className="row">
                    <div className="col d-flex justify-content-between">
                      <h3 className="m-0 px-5 fs-1 py-4">Prize</h3>
                      <button
                        type="button"
                        className="btn btn-primary mx-5 my-4"
                        onClick={(e) => handleAddPrize(e, formik)}
                      >
                        ADD
                      </button>
                    </div>
                  </div>

                  <div className=" p-5">
                    {/** Prize */}
                    <div className="row mb-6">
                      <div className="col-12">
                        <div className="row mb-4">
                          <div className="col-6">
                            <p className="m-0">Prize Name</p>
                          </div>
                          <div className="col-6">
                            <p className="m-0">Prize Amount</p>
                          </div>
                        </div>

                        <FieldArray name="prize">
                          {() =>
                            formik.values.prize.length > 0 &&
                            formik.values.prize.map(
                              (prizeItem: any, i: any) => {
                                const ticketErrors =
                                  // (formik.errors.tickets?.length &&
                                  //   formik.errors.tickets[i]) ||
                                  {} as any;
                                const ticketTouched =
                                  // (formik.touched.tickets?.length &&
                                  //   formik.touched.tickets[i]) ||
                                  {} as any;
                                return (
                                  <div key={i} className="row mb-4">
                                    <div className="col-6">
                                      <Field
                                        name={`prize.${i}.prize_name`}
                                        type="text"
                                        placeholder="prize title"
                                        className={
                                          "form-control form-control-md form-control-solid" +
                                          (ticketErrors?.prize_name &&
                                          ticketTouched?.prize_name
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name={`prize.${i}.prize_name`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="col-6">
                                      <Field
                                        name={`prize.${i}.prize_amount`}
                                        type="text"
                                        placeholder="prize amount"
                                        className={
                                          "form-control form-control-md form-control-solid" +
                                          (ticketErrors.prize_amount &&
                                          ticketTouched.prize_amount
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name={`prize.${i}.prize_amount`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )
                          }
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </div>

                {/** Registration Info. */}
                <div
                  className="current card mb-6"
                  data-kt-stepper-element="content"
                >
                  <div className="row">
                    <div className="col">
                      <h3 className="m-0 px-5 fs-1 py-4">Registration Info.</h3>
                    </div>
                  </div>

                  <div className=" p-5">
                    {/** Registration fee */}
                    <div className="row mb-6">
                      <div className="col-3">
                        <h4 className="fw-bold text-dark">Registration fee</h4>
                      </div>

                      <div className="col-9">
                        <div className="row">
                          <div className="col-3">
                            <div className="form-check form-check-custom form-check-solid form-check-md">
                              <input
                                {...formik.getFieldProps("registration_fee")}
                                className="form-check-input"
                                type="radio"
                                value="1"
                                id="paid"
                                name="registration_fee"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="paid"
                              >
                                Paid
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-check form-check-custom form-check-solid form-check-md">
                              <input
                                {...formik.getFieldProps("registration_fee")}
                                className="form-check-input"
                                type="radio"
                                value="2"
                                id="free"
                                name="registration_fee"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="free"
                              >
                                Free
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/** Amount */}
                    {formik.values.registration_fee == "1" && (
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Amount</h4>
                        </div>

                        <div className="col-9">
                          <input
                            {...formik.getFieldProps("registration_amount")}
                            type="text"
                            className="form-control form-control-md form-control-solid"
                            placeholder="Enter Amount"
                            name="registration_amount"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/** Tournament Type */}
                <div
                  className="current card mb-6"
                  data-kt-stepper-element="content"
                >
                  <div className="row">
                    <div className="col">
                      <h3 className="m-0 px-5 fs-1 py-4">Tournament Type</h3>
                    </div>
                  </div>

                  <div className=" p-5">
                    {/** Type */}
                    <div className="row mb-6">
                      <div className="col-3">
                        <h4 className="fw-bold text-dark">Type</h4>
                      </div>

                      <div className="col-9">
                        <div className="row">
                          <div className="col-3">
                            <div className="form-check form-check-custom form-check-solid form-check-md">
                              <input
                                {...formik.getFieldProps("tournament_type")}
                                className="form-check-input"
                                type="radio"
                                value="1"
                                id="paid"
                                name="tournament_type"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="public"
                              >
                                Public
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-check form-check-custom form-check-solid form-check-md">
                              <input
                                {...formik.getFieldProps("tournament_type")}
                                className="form-check-input"
                                type="radio"
                                value="2"
                                id="free"
                                name="tournament_type"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="private"
                              >
                                Private
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/** Invite Members */}
                    {formik.values.tournament_type == "2" && (
                      <div className="row mb-6">
                        <div className="col-3 m-auto">
                          <h4 className="fw-bold text-dark">Invite Members</h4>
                        </div>

                        <div className="col-9">
                          <ReactSelect
                            {...formik.getFieldProps("invite_members")}
                            name="invite_members"
                            isMulti
                            onChange={(selectedOption: any) =>
                              formik.setFieldValue(
                                "invite_members",
                                selectedOption
                              )
                            }
                            options={userListOption}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/** Barcode */}
                {/* <div
                  className="current card mb-6"
                  data-kt-stepper-element="content"
                >
                  <div className="row">
                    <div className="col">
                      <h3 className="m-0 px-5 fs-1 py-4">Barcode</h3>
                    </div>
                  </div>

                  <div className=" p-5">
                    <div className="row mb-6">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-md btn-secondary"
                          data-kt-menu-trigger="click"
                          data-kt-menu-overflow="true"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-flip="top-end"
                        >
                          Generate Barcode
                        </button>
                      </div>

                      <div className="col-9 d-flex justify-content-end">
                        <img
                          src={toAbsoluteUrl("/media/svg/files/css.svg")}
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/** Registration Close Date */}
                <div
                  className="current card mb-6"
                  data-kt-stepper-element="content"
                >
                  <div className=" p-5">
                    {/** Type */}
                    <div className="row mb-6">
                      <div className="col-3 m-auto">
                        <h4 className="fw-bold  text-dark">
                          Registration Close Date
                        </h4>
                      </div>

                      <div className="col-9">
                        <input
                          type="date"
                          {...formik.getFieldProps("register_end_date")}
                          className={clsx(
                            "form-control form-control-md form-control-solid"
                          )}
                          placeholder=""
                          name="register_end_date"
                        />
                        {formik.touched.register_end_date &&
                          formik.errors.register_end_date && (
                            <div className="fv-plugins-message-container">
                              <span role="alert">
                                {formik.errors.register_end_date}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => props.handleOpenSecondForm()}
                    className="btn btn-primary"
                  >
                    <i className="bi bi-arrow-left fs-4 me-2"></i>Back
                  </button>
                  <div>
                    <button type="submit" className="btn btn-primary me-3">
                      Save Draft
                    </button>
                    <button type="button" className="btn btn-success">
                      Publish Tournament
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateTournamentSecondForm;

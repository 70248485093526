import { API_CONST, ENDPOINT } from "../Configs";
import { STATUS_CODE } from "../helpers/Constants";
import { commonConstants } from "./constants";
import api_helper from "../Configs/API_helper";
const api = new api_helper();

export const getTypeList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: commonConstants?.TYPE_LIST_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.TYPESLIST}`);
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: commonConstants?.TYPE_LIST_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: commonConstants?.TYPE_LIST_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const getFormatList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: commonConstants?.FORMAT_LIST_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.FORMATLIST}`);
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: commonConstants?.FORMAT_LIST_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: commonConstants?.FORMAT_LIST_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const getSportsList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: commonConstants?.SPORTS_LIST_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.SPORTLIST}`);
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: commonConstants?.SPORTS_LIST_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: commonConstants?.SPORTS_LIST_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const getCategoryList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: commonConstants?.CATEGORY_LIST_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.CATEGORYLIST}`);
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: commonConstants?.CATEGORY_LIST_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: commonConstants?.CATEGORY_LIST_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

export const getlevelList = () => {
  return async (dispatch: Function) => {
    dispatch({ type: commonConstants?.LAVEL_LIST_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.LEVELSLIST}`);
    if (response.status === 200) {
      const { data, statuscode, message } = response.data;
      if (statuscode === STATUS_CODE.SUCCESS) {
        dispatch({
          type: commonConstants?.LAVEL_LIST_SUCCESS,
          payload: {
            data,
            message,
            status: statuscode,
          },
        });
      } else {
        dispatch({
          type: commonConstants?.LAVEL_LIST_FAILURE,
          payload: {
            data: data,
            message: message,
          },
        });
      }
    }
  };
};

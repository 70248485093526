import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import { Provider } from "react-redux";
import configureStore from "./store/store";
import { PersistGate } from "redux-persist/integration/react";

const { PUBLIC_URL } = process.env;

const reduxStore = configureStore();

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={reduxStore.store}>
      <PersistGate persistor={reduxStore.persistor}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);

reportWebVitals();

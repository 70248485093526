import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storageLocation from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import { LOGIN_STORAGE_KEY } from "../Configs";
import rootAllReducer from "../reducers";
let initialState = {};
const configureStore = () => {
  const create =
    window && (window as any).devToolsExtension
      ? (window as any).devToolsExtension()(createStore)
      : createStore;
  // const createStoreWithMiddleware = applyMiddleware(storage)(create);
  const createStoreWithMiddleware = applyMiddleware(thunk)(create);
  const appReducer = rootAllReducer;
  const rootReducer = (state: any, action: any) => {
    if (action.type === "LOGOUT_SUCCESS") {
      state = undefined;
      sessionStorage.removeItem(LOGIN_STORAGE_KEY);
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    return appReducer(state, action);
  };
  const persistedReducer = persistReducer(
    {
      key: "muthrab:root",
      storage: storageLocation,
      transforms: [
        encryptTransform({
          secretKey: "muthrab",
          onError: function (error) {
            // Handle the error
            console.log("error", error);
          },
        }),
      ],
    },
    rootReducer
  );
  const store = createStoreWithMiddleware(persistedReducer, initialState);
  const persistor = persistStore(store);
  return { store, persistor };
};
export default configureStore;

import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
// import { PageTitle } from "../../../_metronic/layout/core";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, KTSVG } from "../../../_metronic/helpers";
import { DeletPopup } from "../../DeletePopup/DeletePopup";
import {
  getOngoingTournamentList,
  getPreviousTournamentList,
  getUpcomingTournamentList,
} from "../../../actions/tournamentsConstants";
import { IAMGE_BASE_URL } from "../../../Configs";
import moment from "moment";
import { useHistory } from "react-router-dom";

const Tournaments: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getOngoingTournamentList());
  }, []);

  const tournamentData = useSelector((state: any) => state.tournament);

  const [selectedDebates, setSelectedDebates] = useState("Ongoing Tournaments");

  const handleChangeTab = (tabType: string) => {
    setSelectedDebates(tabType);
    if (tabType === "Previous Tournaments") {
      dispatch(getPreviousTournamentList());
    } else if (tabType === "Upcoming Tournaments") {
      dispatch(getUpcomingTournamentList());
    } else {
      dispatch(getOngoingTournamentList());
    }
  };

  //---------delete on past debate---------------
  const [pastDelete, setPastDelete] = useState(false);
  const [pastDeleteId, setPastDeleteId] = useState("");
  const deleteId = (row: string) => {
    setPastDeleteId(row);
    setPastDelete(true);
  };

  const handleClose = () => {
    setPastDelete(false);
    // setShow(false)
  };

  const pastDeleteSubmit = (e: Event) => {
    e.preventDefault();
    // dispatch(deletePastDebateList(pastDeleteId));
    setTimeout(() => {
      // dispatch(getPastDebateList(selectedDate));
      setPastDelete(false);
    }, 1000);
  };

  const handleDeleteTournament = (item: any) => {
    console.log("handleDeleteTournament", item);
  };

  const handleEditTournament = (item: any) => {
    console.log("handleEditTournament", item);
  };

  const createOrganizer = () => {
    history.push("/tournaments/add");
  };

  const TournamentBlockComponent = (selectedTournamentTab: string) => {
    let tournamentList = [];
    console.log("tournamentData", tournamentData);

    if (selectedTournamentTab === "Ongoing Tournaments") {
      tournamentList = tournamentData?.ongoingTournamentList;
    } else if (selectedTournamentTab === "Previous Tournaments") {
      tournamentList = tournamentData?.previousTournamentList;
    } else if (selectedTournamentTab === "Upcoming Tournaments") {
      tournamentList = tournamentData?.upcomingTournamentList;
    }

    if (tournamentList?.length > 0) {
      return tournamentList?.map((item: any) => (
        <div
          // key={item?.id}
          key={item}
          className="col-xs-12 col-md-6 col-lg-6 col-xl-4 p-3 tournament-block"
        >
          <div className="d-flex flex-wrap main flex-sm-nowrap p-3 m-1">
            <div className="me-5">
              <div className="position-relative">
                <img
                  className="tournament-img"
                  src={`${IAMGE_BASE_URL}/${item?.tournament_image}`}
                  onError={(
                    e: React.SyntheticEvent<HTMLImageElement, Event> | any
                  ) => (e.target.src = "/media/avatars/blank.png")}
                  alt="image"
                />
              </div>
            </div>
            <div className="flex-grow-1 mt-3">
              <div className="d-flex justify-content-between align-items-start flex-wrap">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1">
                    <a
                      href="#"
                      className="text-hover-primary fs-2 fw-bold me-1"
                    >
                      {item?.tournament_name}
                    </a>
                  </div>
                  <div className="fw-normal fs-7 mb-2 pe-2">
                    <span>Type : {item?.type?.type_name}</span>
                  </div>
                  <div className="d-flex flex-wrap fw-normal fs-6 mb-2 pe-2">
                    <a
                      href="#"
                      className="d-flex align-items-center fw-normal text-hover-primary me-5 mb-2"
                    >
                      <span className="svg-icon svg-icon-4 me-1">
                        <i className="bi bi-joystick fs-3 me-2"></i>
                        single Elimination
                      </span>
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center fw-normal text-hover-primary me-5 mb-2"
                    >
                      <span className="svg-icon svg-icon-4 me-1">
                        <i className="bi bi-people-fill fs-3 me-2"></i>
                        16/16
                      </span>
                    </a>
                  </div>
                  <div className="d-flex flex-wrap fw-normal fs-6 mb-2 pe-2">
                    <a
                      href="#"
                      className="d-flex align-items-center fw-normal text-hover-primary me-5 mb-2"
                    >
                      <span className="svg-icon svg-icon-4 me-1">
                        <i className="bi bi-calendar3 fs-3 me-2"></i>
                        {moment(item?.start_date).format("DD-MM-YYYY")}
                      </span>
                    </a>
                    <a
                      href="#"
                      className="d-flex align-items-center fw-normal text-hover-primary me-5 mb-2"
                    >
                      <span className="svg-icon svg-icon-4 me-1">
                        <i className="bi bi-clock fs-3 me-2"></i>
                        {item?.start_time}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {selectedTournamentTab === "Upcoming Tournaments" && (
              <div className="d-flex">
                <div className="me-0">
                  <button
                    className="btn btn-sm btn-icon btn-active-color-primary"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                  >
                    <i className="bi bi-three-dots-vertical fs-3"></i>
                  </button>
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
                    data-kt-menu="true"
                  >
                    <div className="menu-item px-3">
                      <div
                        className="menu-link pb-2 px-3 fs-7 text-uppercase"
                        onClick={() => handleEditTournament(item)}
                      >
                        Edit
                      </div>
                    </div>
                    <div className="menu-item px-3">
                      <div
                        className="menu-link pb-2 px-3 fs-7 text-uppercase"
                        onClick={() => handleDeleteTournament(item)}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ));
    } else {
      return (
        <div className="text-center">
          <h3>No Data Found</h3>
        </div>
      );
    }
  };
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: `Clashes/${selectedDebates}` })}
      </PageTitle> */}
      <div className="card p-5 mb-3">
        <div className="d-flex align-items-center justify-content-between my-3 mb-7 position-relative">
          <div className="search-box mb-2 d-inline-block">
            <div className="position-relative">
              <span> </span>
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y"
              />
              <input
                type="text"
                className="form-control form-control-solid px-15"
                // value={value || ""}
                onChange={(e) => {
                  // setValue(e.target.value);
                  // onChange(e.target.value);
                }}
                name="search"
                placeholder="Search..."
              />
            </div>
          </div>
          <button
            type="button"
            className="btn btn-md btn-primary"
            data-kt-menu-dismiss="true"
            onClick={() => createOrganizer()}
          >
            Create Tournament
          </button>
        </div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="d-flex align-items-center justify-content-around me-2 tournament-tab">
                <button
                  className={
                    selectedDebates === "Ongoing Tournaments"
                      ? "nav-link btn btn-sm btn-color-muted btn-active w-100 btn-active-light-primary active fw-bolder px-4 me-1 "
                      : "nav-link btn btn-sm btn-color-muted btn-active w-100 btn-active-light-primary fw-bolder px-4 me-1 "
                  }
                  type="button"
                  title="Ongoing Tournaments"
                  onClick={() => handleChangeTab("Ongoing Tournaments")}
                >
                  Ongoing Tournaments
                </button>
                <button
                  className={
                    selectedDebates === "Previous Tournaments"
                      ? "nav-link btn btn-sm btn-color-muted btn-active w-100 btn-active-light-primary active fw-bolder px-4 me-1 "
                      : "nav-link btn btn-sm btn-color-muted btn-active w-100 btn-active-light-primary fw-bolder px-4 me-1 "
                  }
                  type="button"
                  title="Previous Tournaments"
                  onClick={() => handleChangeTab("Previous Tournaments")}
                >
                  Previous Tournaments
                </button>
                <button
                  className={
                    selectedDebates === "Upcoming Tournaments"
                      ? "nav-link btn btn-sm btn-color-muted btn-active w-100 btn-active-light-primary active fw-bolder px-4 me-1 "
                      : "nav-link btn btn-sm btn-color-muted btn-active w-100 btn-active-light-primary fw-bolder px-4 me-1 "
                  }
                  type="button"
                  title="Upcoming Tournaments"
                  onClick={() => handleChangeTab("Upcoming Tournaments")}
                >
                  Upcoming Tournaments
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pastDelete && (
        <DeletPopup
          show={pastDelete}
          handleClose={handleClose}
          submitPopup={pastDeleteSubmit}
        />
      )}
      <div className="card">
        <div className="card-body">
          <div className="row">{TournamentBlockComponent(selectedDebates)}</div>
        </div>
      </div>
    </>
  );
};

export default Tournaments;

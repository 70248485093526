import { commonConstants } from "../actions/constants";

const initialState = {
  typeList: [],
  formatList: [],
  sportsList: [],
  categoryList: [],
  lavelList: [],
  status: 2,
  message: "",
};

type TournamentType = {
  payload: {
    data: [] | any;
    status: number;
    message: string;
  };
  type: string;
};

export default (state = initialState, action: TournamentType) => {
  switch (action.type) {
    case commonConstants.TYPE_LIST_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case commonConstants.TYPE_LIST_SUCCESS:
      state = {
        ...state,
        typeList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case commonConstants.TYPE_LIST_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;

    case commonConstants.FORMAT_LIST_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case commonConstants.FORMAT_LIST_SUCCESS:
      state = {
        ...state,
        formatList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case commonConstants.FORMAT_LIST_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;

    case commonConstants.SPORTS_LIST_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case commonConstants.SPORTS_LIST_SUCCESS:
      state = {
        ...state,
        sportsList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case commonConstants.SPORTS_LIST_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;

    case commonConstants.CATEGORY_LIST_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case commonConstants.CATEGORY_LIST_SUCCESS:
      state = {
        ...state,
        categoryList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case commonConstants.CATEGORY_LIST_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;

    case commonConstants.LAVEL_LIST_REQUEST:
      state = {
        ...state,
        status: 2,
      };
      break;
    case commonConstants.LAVEL_LIST_SUCCESS:
      state = {
        ...state,
        lavelList: action.payload.data,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
    case commonConstants.LAVEL_LIST_FAILURE:
      state = {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
      break;
  }
  return state;
};

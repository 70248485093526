import { authConstants } from "./constants";
import { API_CONST, ENDPOINT } from "../Configs";
import api_helper from "../Configs/API_helper";
import { USER_ROLE } from "../helpers/Constants";
const api = new api_helper();

export const adminlogin = (email: string, password: string) => {
  return async (dispatch: Function) => {
    const response = await api.post(`${API_CONST}${ENDPOINT.ADMINLOGIN}`, {
      data: {
        email_address: email,
        password,
      },
    });
    if (response.status === 200) {
      const { data } = response;
      if (data.success) {
        localStorage.setItem("token", data.data);
        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            data: data.data,
            message: data.message,
            userrole: USER_ROLE.ADMIN,
            status: data.status,
          },
        });
      } else {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: {
            data: data.data,
            message: data.message,
          },
        });
      }
    }
  };
};

export const login = (userloginData: any) => {
  return async (dispatch: Function) => {
    const response = await api.post(`${API_CONST}${ENDPOINT.ORGANISERLOGIN}`, {
      data: userloginData,
    });
    if (response.status === 200) {
      const { data } = response;
      if (data.success) {
        localStorage.setItem("token", data.data);
        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            data: data.data,
            message: data.message,
            userrole: USER_ROLE.ORGANISER,
            status: data.status,
          },
        });
      } else {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: {
            data: data.data,
            message: data.message,
          },
        });
      }
    }
  };
};

export const logoutUser = () => async (dispatch: Function) => {
  try {
    dispatch({
      type: authConstants.LOGOUT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: authConstants.LOGOUT_FAILURE,
    });
  }
};

export const isUserLoggedIn = () => {
  return async (dispatch: Function) => {
    const token = localStorage.getItem("token");
    if (token) {
      const data = JSON.parse(localStorage.getItem("user") as string);
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          data,
        },
      });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: {
          error: { error: "Failed to login" },
        },
      });
    }
  };
};

export const forgotPassword = (info: any, callBack: Function) => {
  return async (dispatch: Function) => {
    dispatch({ type: authConstants.FORGOT_PASSWORD_REQUEST });
    const response = await api.post(
      `${API_CONST}${ENDPOINT.FORGOTPASSWORD_SENDOTP}`,
      {
        data: info,
      }
    );
    if (response.status === 200) {
      const { data } = response;
      callBack(data);
      dispatch({
        type: authConstants.FORGOT_PASSWORD_SUCCESS,
        payload: {
          data,
        },
      });
    }
  };
};

export const verifyPassword = (info: any, callBack: Function) => {
  return async (dispatch: Function) => {
    dispatch({ type: authConstants.VERIY_OTP_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.VERIFYOTP}`, {
      data: info,
    });
    if (response.status === 200) {
      const { data } = response;
      callBack(data);
    }
  };
};

export const resetPassword = (info: any, callBack: Function) => {
  return async (dispatch: Function) => {
    dispatch({ type: authConstants.VERIY_OTP_REQUEST });
    const response = await api.post(`${API_CONST}${ENDPOINT.RESETPASSWORD}`, {
      data: info,
    });
    if (response.status === 200) {
      const { data } = response;
      callBack(data);
    }
  };
};

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import dashbordReducer from "./dashbordReducer";
import organizerReducer from "./organizerReducer";
import tournamentReducer from "./tournamentReducer";
import commonReducer from "./commonReducer";

const rootAllReducer = combineReducers({
  auth: authReducer,
  dashbord: dashbordReducer,
  organizer: organizerReducer,
  tournament: tournamentReducer,
  common: commonReducer,
});

export default rootAllReducer;
